/**
 * @file Datepicker Close
 */
'use strict'

/**
 * @classname DatepickerClose
 * @classdesc datepickerクリア
 */
export default class DatepickerClose {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {instance} controller - controller instance
	 */
	constructor($e, controller) {
		console.log('DatepickerClose.constructor')
		const $datepicker = $e.closest('.form-group').find('input')
		$e.on('click', () => {
			controller.clearSelectedDate($datepicker)
		})
	}
}
