/**
 * @file EditorJsの確認用
 */
'use strict'

/**
 * @classname EditorTest
 * @classdesc EditorJsの確認用
 */
export default class EditorTest {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('EditorTest.constructor')
		this._$e = $e
		this._controller = controller
		this.area = $('.block-editor-display-area')
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof EditorTest
	 * @return {undefined}
	 */
	onClick() {
		console.log('EditorTest.onClick')
		this.area.children().remove()
		this._controller.createHtml()
		setTimeout(() => {
			const val = $('#html').val()
			console.log(val)
			this.area.append(val)
		}, 2000)
	}
}
