/**
 * @file Air Datepicker
 */
'use strict';
import '../../Libs/datepicker';

/**
 * @classname DatePicker
 * @classdesc Air Datepicker
 */
export default class DatePicker {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {instance} controller
	 */
	constructor($e, controller) {
		console.log('DatePicker.constructor');
		this._$e = $e;
		this._controller = controller;
		const position = this._$e.attr('data-picker-position') || 'right bottom'
		const offset = this._$e.attr('data-picker-offset') ? Number(this._$e.attr('data-picker-offset')) : 47
		const clear = !!(this._$e.attr('data-clear'));
		let minDate = this._$e.attr('data-mindate') || '';
		let maxDate = this._$e.attr('data-maxdate') || '';
		if (minDate !== '') {
			if (minDate.match(/\d{4}\/\d{2}\/\d{2}/)) {
				console.log('minDate match date');
				const now = new Date();
				minDate = minDate.split('/');
				minDate = new Date(minDate[0], minDate[1] - 1, minDate[2], now.getHours(), 0, 0);
			} else if (minDate.match(/^-?\d+$/)) {
				console.log('minDate match number');
				let mindate = new Date();
				mindate.setDate(mindate.getDate() + parseInt(minDate, 10));
				mindate.setMinutes(0);
				mindate.setSeconds(0);
				minDate = mindate;
			}
		}
		if (maxDate !== '') {
			if (maxDate.match(/\d{4}\/\d{2}\/\d{2}/)) {
				console.log('maxDate match date');
				maxDate = maxDate.split('/');
				maxDate = new Date(maxDate[0], maxDate[1] - 1, maxDate[2], now.getHours(), 0, 0);
			} else if (maxDate.match(/^-?\d+$/)) {
				console.log('maxDate match number');
				let maxdate = new Date();
				maxdate.setDate(maxdate.getDate() + parseInt(maxDate, 10));
				maxdate.setMinutes(0);
				maxdate.setSeconds(0);
				maxDate = maxdate;
			} else {
				maxDate = '';
			}
		}
		this._time = !!($e.attr('data-time'));
		const navTitles = {
			days: 'yyyy 年 mm 月',
			months: 'yyyy',
			years: 'yyyy1 - yyyy2'
		};

		const opts = {
			language: 'ja',
			minDate: minDate,
			maxDate: maxDate,
			timepicker: this._time,
			autoClose: true,
			clearButton: clear,
			position: position,
			offset: offset,
			navTitles: navTitles,
			onShow: (inst, animationCompleted) => {
				if (!clear) {
					if (animationCompleted) {
						const value = $e.val();
						$e.attr('data-value', value);
					}
				}
			},
			onHide: (inst, animationCompleted) => {
				if (!clear) {
					if (!animationCompleted) {
						if (!$e.val() || $e.val() === '') {
							$e.val($e.attr('data-value'));
						}
						$e.removeAttr('data-value');
					}
				}
			},
			onSelect: () => {
				$e.removeClass('is-invalid');
				$e.closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			}
		};
		this._datepicker = $e.datepicker(opts);
		let value = $e.val();
		console.log(value);
		const dp = this._$e.datepicker().data('datepicker');
		if (value) {
			// 値が入っている場合は時間や分を削り、選択済みにする
			this._time ? $e.val(value.substr(0, 16)) : $e.val(value.substr(0, 10))
			dp.selectDate($e.val());
		}
		this._$e.next('.input-group-append').on('click', (e) => this.onClick(e));
		this._controller.on('clearSelectedDate', () => {
			console.log('clearSelectedDate');
			if($e.attr('name') === this._controller._$datepicker.attr('name')) dp.clear();
		});
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormDatepickerView
	 * @param {event} e - click event
	 * @return {undefined}
	 */
	onClick(e) {
		console.log('FormDatepickerView.onClick');
		e.preventDefault();
		e.stopPropagation();
		if(this._$e.attr('disabled')) return;
		if(this._$e.hasClass('-disabled-')) return;
		const value = this._$e.val();
		const dp = this._$e.datepicker().data('datepicker');
		dp.show();
		if(value !== ''){
			this._$e.attr('data-value', 'value');
			this._$e.val(value);
		}
	};
}
