/**
 * @file マトリクス設定
 */
"use strict";

import Common from "../../Utils/Common";

/**
 * @classname Matrix
 * @classdesc マトリクス表
 */
export default class Matrix {
	/**
	 * @constructor
	 * @param {jQuery} $e - form jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log("Matrix.constructor");
		this._$e = $e;
		this._$customizeSelectBox = $e.find("#base-customize");
		this._$partsSelectBox = $e.find("#base-parts");
		this._$combinationContainer = $e.find(".matrix-combination");
		this._$form = $e.find("form");
		this._$notAvailableInput = this._$form.find("input[type='hidden']#not_available");
		this._$submitButton = this._$form.find("button[type='submit']");
		this._$allCheckbox;
		this.partsData = [];
		(async () => {
			const res = await fetch(window.const.API_URL.GET_PARTS);
			const result = await res.json();
			if (result.code !== 0) {
				Common.showErrorModal("パーツの取得に失敗しました。時間を空けて再度お試しください。");
				return;
			}
			this.partsData = result.result;
			this._$customizeSelectBox.on("change", (e) => this.onCustomizeSelectBoxChange(e));
			this._$partsSelectBox.on("change", (e) => this.onPartsSelectBoxChange(e));
		})();
	}

	/**
	 * 部位のセレクトボックスの変更コールバック
	 *
	 * @memberof Matrix
	 * @return {undefined}
	 */
	onCustomizeSelectBoxChange(e) {
		console.log("Matrix.onCustomizeSelectBoxChange");
		this._$partsSelectBox.prop("disabled", true);
		this._$partsSelectBox.children(":first-child").siblings().remove();
		if (!e.target.value) {
			this._$partsSelectBox.children(":first-child").text("カスタマイズを選択してください");
			this._$submitButton.prop("disabled", true);
			this._$submitButton.removeClass("mt50");
			this._$combinationContainer.children(":last-child").siblings().remove();
			return;
		}
		this._customize = e.target.value;
		this._$partsSelectBox.children(":first-child").text("選択してください");
		this._$partsSelectBox.prop("disabled", false);
		const tempPartsElements = this.partsData.map((parts) => {
			if (parts.parts_code !== e.target.value) return;
			return `<option value="${parts.id}">${parts.parts_name}</option>`;
		});
		const partsElements = tempPartsElements.filter(Boolean);
		if (partsElements.length === 0) {
			this._$partsSelectBox.prop("disabled", true);
			this._$partsSelectBox.children(":first-child").siblings().remove();
			this._$partsSelectBox.children(":first-child").text("パーツがありません");
			this._$submitButton.prop("disabled", true);
			this._$submitButton.removeClass("mt50");
			this._$combinationContainer.children(":last-child").siblings().remove();
			return;
		}
		this._$partsSelectBox.append(partsElements.join(""));
	}
	/**
	 * パーツのセレクトボックスの変更コールバック
	 *
	 * @memberof Matrix
	 * @return {undefined}
	 */
	async onPartsSelectBoxChange(e) {
		console.log("Matrix.onPartsSelectBoxChange");
		const parentPartsId = e.target.value;
		this._$combinationContainer.children(":last-child").siblings().remove();
		if (!parentPartsId) {
			this._$submitButton.prop("disabled", true);
			this._$submitButton.removeClass("mt50");
			return;
		}
		const parentPartsNotAvailables = JSON.parse(
			this.partsData.find((parts) => parts.id === Number(parentPartsId)).not_available
		);
		console.log(parentPartsNotAvailables);

		let prevPartsCode = "";
		
		const combinationElementing = [...this.partsData].filter((parts) => parts.parts_code !== this._customize).map(async (parts) => {
			if (prevPartsCode === parts.parts_code) {
				prevPartsCode = parts.parts_code;

				return `
					<div class="row form-group">
						<div class="col-md-6 col-form-label">
							${parts.parts_name}
						</div>
						<div class="col-md-6">
							<div class="input-checkbtn">
								<input
									class="input-checkbtn-input"
									id="${parts.id}"
									value="${parts.id}"
									type="checkbox"
									${parentPartsNotAvailables.includes(parts.id) ? "" : "checked"}
								/><label class="input-checkbtn-label" for="${parts.id}">併用可能</label>
							</div>
						</div>
					</div>
				`;
			} else {
				prevPartsCode = parts.parts_code;
				return `
					<h2 class="heading-sub-title">${await this.getPartsCodeName(parts.parts_code)}</h2>
					<div class="row form-group">
						<div class="col-md-6 col-form-label">
							${parts.parts_name}
						</div>
						<div class="col-md-6">
							<div class="input-checkbtn">
								<input
									class="input-checkbtn-input"
									id="${parts.id}"
									value="${parts.id}"
									type="checkbox"
									${parentPartsNotAvailables.includes(parts.id) ? "" : "checked"}
								/><label class="input-checkbtn-label" for="${parts.id}">併用可能</label>
							</div>
						</div>
					</div>
				`;
			}
		});
		const combinationElements = await Promise.all(combinationElementing);
		this._$combinationContainer.prepend(combinationElements.join(""));
		this._$submitButton.prop("disabled", false);
		this._$submitButton.addClass("mt50");
		this._$allCheckbox = this._$combinationContainer.find("input[type='checkbox']");
		this._$allCheckbox.on("change", () => this.onPartsCheckboxChange());
	}

	/**
	 * パーツチェックボックスの変更コールバック
	 *
	 * @memberof Matrix
	 * @return {undefined}
	 */
	onPartsCheckboxChange() {
		console.log("Matrix.onPartsCheckboxChange");
		const notAvailable = [];
		this._$allCheckbox.each((idx, checkbox) => {
			if ($(checkbox).prop("checked")) return;
			notAvailable.push(Number($(checkbox).val()));
		});
		this._$notAvailableInput.val(JSON.stringify(notAvailable));
	}

	/**
	 * パーツコードに紐づく部位の名称を取得
	 *
	 * @memberof Matrix
	 * @return {undefined}
	 */
	async getPartsCodeName(code) {
		const res = await fetch(window.const.STATIC_URL.PARTS_CODE);
		const result = await res.json();
		return result[code];
	}
}
