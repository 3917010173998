/**
 * @file 掲示板プレビュー
 */
'use strict'

/**
 * @classname PreviewArticle
 * @classdesc 掲示板プレビュー
 */
export default class PreviewArticle {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('PreviewArticle.constructor')
		this._$e = $e
		this._controller = controller
		this._$formText = $e.attr('data-target-form')
		this._$form = $(this._$formText)
		this._$submitBtn = $(`[data-form="${this._$formText}"]`)
		this._action = this._$e.attr('data-action');
		$e.on('click', () => this.onClick())
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof PreviewArticle
	 * @return {undefined}
	 */
	onClick() {
		console.log('PreviewArticle.onClick')
		console.log(this._$form)
		console.log(this._$submitBtn)
		// ここで追加したinputは一回送られたら、その時にSubmit.jsの中の処理で削除される
		this._$form.append("<input type='hidden' name='mode-preview' value='pre' />")

		this._$form.attr('target', '_blank')
		this._$form.attr('action', this._action);

		this._$submitBtn.trigger('click')
	}
}
