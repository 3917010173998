/**
 * @file Basic Controller
 */

'use strict';
import Listener from '../Utils/Listener';
import BasicModel from '../Model/BasicModel';
import Common  from '../Utils/Common';

let instance = null;

/**
 * @classname Basic
 * @classdesc Basic Controller
 */
export default class Basic {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Basic.constructor');

		this._model = new BasicModel();

		this._listener = new Listener();

		this._model.on('saveFilterComplete', () => {
			this._listener.trigger('saveFilterComplete');
		});
		this._model.on('getSavedFiltersComplete', () => {
			this._filters = this._model._data;
			this._listener.trigger('getSavedFiltersComplete');
		});
		this._model.on('deleteFilterComplete', () => {
			this._listener.trigger('deleteFilterComplete');
		});

		instance = this;
		return instance;
	};


	/**
	 * 検索条件保存
	 *
	 * @memberof Basic
	 * @param {string} searchType - 検索タイプ
	 * @param {string} filterName - 検索条件の保存名
	 * @param {string} searchUrl - 検索URL
	 * @return {undefined}
	 */
	saveFilter(searchType, filterName, searchUrl) {
		console.log('Basic.saveFilter');
		this._model.saveFilter(searchType, filterName, searchUrl);
	};


	/**
	 * 保存済検索条件リストの取得
	 *
	 * @memberof Basic
	 * @param {string} searchType - 検索タイプ
	 * @return {undefined}
	 */
	getSavedFilters(searchType) {
		console.log('Basic.getSavedFilters');
		this._model.getSavedFilters(searchType);
	};


	/**
	 * 保存済検索条件削除
	 *
	 * @memberof Basic
	 * @param {number} filterId - フィルターID
	 * @return {undefined}
	 */
	deleteFilter(filterId) {
		console.log('Basic.deleteFilter');
		this._model.deleteFilter(filterId);
	};


	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
