/**
 * @file チェックボックスがtrueになったらtargetを活性化する
 */
'use strict'

/**
 * @classname CheckActivation
 * @classdesc チェックボックスがtrueになったらtargetを活性化する
 */
export default class CheckActivation {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-toggle-password')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$trigger = this._$e.find('[data-check-activation-type="trigger"]').first()
		this._$target = this._$e.find('[data-check-activation-type="target"]')
		this._$trigger.on('change', () => this.onClick())
		this.onClick()
	}

	/**
	 * onClickのコールバック
	 *
	 * @memberof CheckActivation
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick :: value`, this._$trigger.prop('checked'))
		const isCheck = this._$trigger.prop('checked')
		if (isCheck) {
			this._$target.each((index, item) => {
				$(item).prop('disabled', false).removeClass('ignore-validate')
			})
		} else {
			this._$target.each((index, item) => {
				$(item).prop('disabled', true).addClass('ignore-validate').removeClass('is-invalid')
			})
			if(this._$e.find('.error-tip.show').length > 0) {
				this._$e.find('.error-tip.show').removeClass('show')
			}
		}
	}
}
