import breaklineIcon from './assets/breaklineIcon.svg';

/**
 * Break Line and divider Block for the Editor.js.
 *
 * @typedef {object} BreakLineData
 * @description Tool's input and output data format
 * @property {boolean} divider — Add a divider line.
 */
export default class BreakLine {
  /**
   * @param {{data: object, api: object}}
   *   data — Previously saved data
   *   api - Editor.js API
   *   readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly

    this.CSS = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      wrapper: 'break-line',
    };

    this.tune = {};

    this.nodes = {
      wrapper: null
    };

    this.tuneButton = null;

    this._data = {};
  }

  /**
   * Renders Tool's view
   * @returns {HTMLDivElement}
   */
  render() {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('style', 'height: 30px;');

    this.nodes.wrapper = wrapper;

    //wrapper.contentEditable = this.readOnly ? 'false' : 'true';

    return wrapper;
  }

  /**
   * Makes buttons with tunes.
   *
   * @returns {HTMLDivElement}
   */
  renderSettings() {
    const wrapper = document.createElement('div');
    return wrapper;
  }


  /**
   * Saves Block data
   * @returns {BreakLineData} - saved data
   */
  save() {
    return this.data;
  }

  /**
   * Gets current Tools`s data
   * @returns {BreakLineData} Current data
   */
  get data() {
    return this._data;
  }

  /**
   * Sets Tools`s data
   *
   * @param {BreakLineData} data — data to set
   */
  set data(data) {
    this._data = data || {};
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: breaklineIcon,
      title: '改行',
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }
}
