/**
 * @file 検索条件クリア
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname ClearSearchCondition
 * @classdesc 検索条件クリア
 */
export default class ClearSearchCondition {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ClearSearchCondition.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof ClearSearchCondition
	 * @return {undefined}
	 */
	onClick() {
		console.log('ClearSearchCondition.onClick');
		location.href = Common.getCurrentURL();
	};
}
