/**
 * @file 画面リロードする(閉じる)ボタン
 */
'use strict'

/**
 * @classname CompleteModal
 * @classdesc 画面リロードする(閉じる)ボタン
 */
export default class CompleteModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CompleteModal.constructor')
		this._$e = $e
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof CompleteModal
	 * @return {undefined}
	 */
	onClick() {
		console.log('reload')
		window.location.reload()
		console.log('CompleteModal.onClick')
	}
}
