/**
 * @file Editor
 */
'use strict'
// core
import EditorJS from '../../Libs/editorjs/core/editor'
import Undo from 'editorjs-undo'
// plugin
import Paragraph from '../../Libs/editorjs/paragraph'
import Header from '../../Libs/editorjs/header'
import ImageTool from '../../Libs/editorjs/image'
import List from '../../Libs/editorjs/list'
import AttachesTool from '../../Libs/editorjs/attaches'
import VideoTool from '../../Libs/editorjs/video'
import BreakLine from '../../Libs/editorjs/breakline'
import RawTool from '@editorjs/raw'
// inline-tool
import Underline from '@editorjs/underline'
import LinkTool from 'editorjs-link-with-target'
import Strikethrough from 'editorjs-strikethrough'
import FontSize from 'editorjs-inline-font-size-tool'
import ColorPlugin from 'editorjs-text-color-plugin'
// whizzy library
import Common from '../../Utils/Common'

/**
 * @classname Editor
 * @classdesc 掲示板投稿WYGIWYG
 */
export default class Editor {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('Editor.constructor')
		// これがないと見た目崩れる
		$('html').addClass('windows')
		this._$e = $e
		this._controller = controller
		const data = $('#data').val()
		const editorjs_type = $e.attr('data-editor-type')
		let editor

		const endpoint = {
			photo:{},
			video:{},
			attaches:{}
		}

		switch (editorjs_type) {
			case 'colume':
				endpoint.photo = window.const.API_URL.BOARD_PHOTO_1;
				endpoint.video = window.const.API_URL.BOARD_VIDEO_1;
				endpoint.attaches = window.const.API_URL.BOARD_ATTACHES_1;
				break;
			case 'experience':
				endpoint.photo = window.const.API_URL.BOARD_PHOTO_2;
				endpoint.video = window.const.API_URL.BOARD_VIDEO_2;
				endpoint.attaches = window.const.API_URL.BOARD_ATTACHES_2;
				break;
			case 'employee':
				endpoint.photo = window.const.API_URL.BOARD_PHOTO_3;
				endpoint.video = window.const.API_URL.BOARD_VIDEO_3;
				endpoint.attaches = window.const.API_URL.BOARD_ATTACHES_3;
				break;
			default:
				endpoint.photo = window.const.API_URL.BOARD_PHOTO_1;
				endpoint.video = window.const.API_URL.BOARD_VIDEO_1;
				endpoint.attaches = window.const.API_URL.BOARD_ATTACHES_1;
				break;
		}

		const tools = {
			paragraph: {
				class: Paragraph,
				inlineToolbar: true,
			},
			link: {
				class: LinkTool,
			},
			underline: Underline,
			strikethrough: {
				class: Strikethrough,
			},
			fontSize: FontSize,
			Color: {
				class: ColorPlugin,
				config: {
					colorCollections: [
						'#FF1300',
						'#EC7878',
						'#9C27B0',
						'#673AB7',
						'#3F51B5',
						'#0070FF',
						'#03A9F4',
						'#00BCD4',
						'#4CAF50',
						'#8BC34A',
						'#CDDC39',
						'#FFBF00',
						'#FFFFFF',
						'#333333',
					],
					defaultColor: '#FF1300',
					type: 'text',
				},
			},
			Marker: {
				class: ColorPlugin,
				config: {
					defaultColor: '#FFBF00',
					type: 'marker',
				},
			},
			image: {
				class: ImageTool,
				config: {
					read_only: true,
					endpoints: {
						accept: 'image/*',
						byFile: endpoint.photo,
						byUrl: endpoint.photo,
					},
					additionalRequestHeaders: {
						'x-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
				},
			},
			// video: {
			// 	class: VideoTool,
			// 	config: {
			// 		read_only: true,
			// 		endpoints: {
			// 			byFile: endpoint.video,
			// 			byUrl: endpoint.video,
			// 		},
			// 		additionalRequestHeaders: {
			// 			'x-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			// 		},
			// 	},
			// },
			attaches: {
				class: AttachesTool,
				config: {
					endpoint: endpoint.attaches,
					additionalRequestHeaders: {
						'x-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
				},
			},
			header: Header,
			list: {
				class: List,
				inlineToolbar: true,
			},
			raw: {
				class: RawTool,
				config: {
					placeholder: 'HTMLを入力',
				},
			},
			breakLine: BreakLine,
		}
		const i18n = {
			messages: {
				ui: {
					toolbar: {
						toolbox: {
							Add: '追加',
							'Click to tune': 'clickで開く',
						},
					},
					blockTunes: {
						toggler: {
							'Click to tune': 'クリックで設定を開く',
							'or drag to move': 'ドラッグで移動',
						},
					},
					inlineToolbar: {
						converter: {
							'Convert to': '変換する',
						},
					},
				},
				blockTunes: {
					delete: {
						Delete: '削除',
					},
					moveUp: {
						'Move up': '上へ',
					},
					moveDown: {
						'Move down': '下へ',
					},
				},
				toolNames: {
					Text: 'テキスト',
					Heading: '見出し',
					Image: '画像',
					List: 'リスト',
					Warning: '注意',
					Alert: '枠',
					Quote: '引用',
					Attaches: '添付',
					Code: 'コード',
					'Break Line': '改行',
					delimiter: '区切り線',
					'Raw HTML': 'HTML',
					Table: '表',
					Video: '動画',
					Link: 'リンク',
					Underline: '下線',
					Strikethrough: '打ち消し線',
					Color: '色',
					'Font Size': 'フォントサイズ',
					Marker: 'マーカー',
					Bold: '太字',
					Italic: '斜体',
				},
				tools: {
					link: {
						'Open in new window': '別ウィンドウで開く',
						Save: '設定',
						'Add a link': 'リンクURL',
					},
					image: {
						Caption: '画像説明文',
						'Select an Image': 'クリックして画像を選択',
					},
					header: {
						Anchor: 'アンカー',
					},
					list: {
						Unordered: '通常リスト',
						Ordered: '番号リスト',
					},
					table: {
						'Add column to left': '左に列を追加',
						'Add column to right': '右に列を追加',
						'Delete column': '列を削除',
						'Add row above': '上に行を追加',
						'Add row below': '下に行を追加',
						'Delete row': '行を削除',
						'With headings': '見出し行あり',
						'Without headings': '見出し行なし',
						Heading: '見出し',
					},
					video: {
						Caption: '動画説明文',
						'Select an Video': 'クリックして動画を選択',
					},
					code: {
						'Enter a code': 'コードを入力',
					},
				},
			},
		}

		console.log('■endpoint.photo：' + endpoint.photo);
		console.log('■endpoint.video：' + endpoint.video);
		console.log('■endpoint.attaches' + endpoint.attaches);

		if (data && data !== '') {
			console.log('dataあり')
			console.log(JSON.parse(data))
			editor = new EditorJS({
				onReady: () => {
					new Undo({ editor })
				},
				data: JSON.parse(data),
				holderId: 'codex-editor',
				tools: tools,
				initialBlock: 'paragraph',
				i18n: i18n,
			})
		} else {
			console.log('dataなし')
			editor = new EditorJS({
				onReady: () => {
					new Undo({ editor })
				},
				holderId: 'codex-editor',
				tools: tools,
				initialBlock: 'paragraph',
				i18n: i18n,
			})
		}
		this._editor = editor
		this._controller.on('onCreateData', () => {
			this.createData()
		})
		this._controller.on('onCreateDataSubmit', () => {
			this.createDataSubmit()
		})
		setTimeout(() => {
			$e.next('.error-tip').remove()
		}, 500)
		setInterval(() => {
			$('html.windows .colorPlugin xy-color-picker').removeAttr('popover')
		}, 500)
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Editor
	 * @return {undefined}
	 */
	createData() {
		console.log('Editor.createData')
		this._editor
			.save()
			.then((outputData) => {
				this._data = outputData
				$('#data').val(JSON.stringify(this._data))
				console.log(JSON.stringify(this._data))
				console.log(this._data)
				this.createHTML()
			})
			.catch((error) => {
				console.log(error)
				Common.showErrorModal('お知らせの作成でエラーが発生しました。')
			})
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Editor
	 * @return {undefined}
	 */
	createHTML() {
		console.log('Editor.createHTML')
		let html = ''
		const blocks = this._data.blocks
		for (let i = 0; i < blocks.length; i++) {
			const item = blocks[i]
			let block = ''
			switch (item.type) {
				case 'paragraph':
					if (item.data.text !== '') {
						if (item.data.alignment === 'center') {
							block = `<p class="board-paragraph tac">${item.data.text}</p>`
						} else if (item.data.alignment === 'right') {
							block = `<p class="board-paragraph tar">${item.data.text}</p>`
						} else {
							block = `<p class="board-paragraph">${item.data.text}</p>`
						}
					}
					break
				case 'header':
					const tag = `h${item.data.level}`
					let classList = ['board-header']
					if (item.data.alignment === 'center') {
						classList.push('board-header--center')
					} else if (item.data.alignment === 'right') {
						classList.push('board-header--right')
					} else {
						classList.push('board-header--left')
					}
					let bgAdjust = false
					if (item.data.lineType === 'normal') {
						classList.push('board-header--normal')
						if (item.data.backgroundColor !== '#ffffff') bgAdjust = true
					} else if (item.data.lineType === 'left-line') {
						classList.push('board-header--left-line')
					} else if (item.data.lineType === 'under-line') {
						classList.push('board-header--under-line')
						if (item.data.backgroundColor !== '#ffffff') bgAdjust = true
					} else if (item.data.lineType === 'updown-line') {
						classList.push('board-header--updown-line')
						if (item.data.backgroundColor !== '#ffffff') bgAdjust = true
					} else if (item.data.lineType === 'border') {
						classList.push('board-header--border')
					}
					const className = classList.join(' ')
					console.log(className)
					if (item.data.backgroundColor === '#ffffff') {
						item.data.backgroundColor = 'transparent'
					}
					if (bgAdjust) {
						block = `<${tag} class="${className}"><div style="color:${item.data.textColor};border-color:${item.data.borderColor};background-color:${item.data.backgroundColor};padding-left:10px;padding-right:10px;">${item.data.text}</div></${tag}>`
					} else {
						block = `<${tag} class="${className}"><div style="color:${item.data.textColor};border-color:${item.data.borderColor};background-color:${item.data.backgroundColor};">${item.data.text}</div></${tag}>`
					}
					break
				case 'image':
					const path = item.data.file.url
					if (path && path !== '') {
						let align = 'left'
						if (item.data.center) {
							align = 'center'
						} else if (item.data.right) {
							align = 'right'
						}
						let size = 'large'
						if (item.data.middle) {
							size = 'middle'
						} else if (item.data.small) {
							size = 'small'
						}
						let lightbox = ' disabled'
						if (item.data.lightbox) lightbox = ''
						let imageStyle = ''
						let rotate = 0
						if (item.data.rotate) {
							rotate = item.data.rotate
						}
						if (rotate === 90 || rotate === 270) {
							const width = item.data.rotateWidth
							const height = item.data.rotateHeight
							const scale = width / height
							imageStyle = `transform:scale(${scale}) rotate(${rotate}deg);`
						} else if (rotate === 180) {
							imageStyle = `transform:rotate(${rotate}deg);`
						}
						if (item.data.caption) {
							if (item.data.withBorder) {
								block = `<div class="board-image board-image--${align} board-image--${size} board-image--border"><a href="javascript:void(0);" class="lightbox-trigger${lightbox}"><img src="${path}" alt="" oncontextmenu="return false;" style="${imageStyle}" data-rotate="${rotate}"></a><p class="board-image__caption">${item.data.caption}</p></div>`
							} else {
								block = `<div class="board-image board-image--${align} board-image--${size}"><a href="javascript:void(0);" class="lightbox-trigger${lightbox}"><img src="${path}" alt="" oncontextmenu="return false;" style="${imageStyle}" data-rotate="${rotate}"></a><p class="board-image__caption">${item.data.caption}</p></div>`
							}
						} else {
							if (item.data.withBorder) {
								block = `<div class="board-image board-image--${align} board-image--${size} board-image--border"><a href="javascript:void(0);" class="lightbox-trigger${lightbox}"><img src="${path}" alt="" oncontextmenu="return false;" style="${imageStyle}" data-rotate="${rotate}"></a></div>`
							} else {
								block = `<div class="board-image board-image--${align} board-image--${size}"><a href="javascript:void(0);" class="lightbox-trigger${lightbox}"><img src="${path}" alt="" oncontextmenu="return false;" style="${imageStyle}" data-rotate="${rotate}"></a></div>`
							}
						}
					}
					break
				case 'list':
					if (item.data.style === 'ordered') {
						block = '<ol class="board-list board-list--order">'
						for (let i = 0; i < item.data.items.length; i++) {
							block += `<li>${item.data.items[i]}</li>`
						}
						block += '</ol>'
					} else {
						block = '<ul class="board-list board-list--normal">'
						for (let i = 0; i < item.data.items.length; i++) {
							block += `<li>${item.data.items[i]}</li>`
						}
						block += '</ul>'
					}
					break
				case 'table':
					if (item.data.withHeadings) {
						block = `<div class="board-table board-table--heading">`
						for (let i = 0; i < item.data.content.length; i++) {
							const row = item.data.content[i]
							block += `<div class="board-table__row">`
							for (let j = 0; j < row.length; j++) {
								block += `<div class="board-table__row__cell">${row[j]}</div>`
							}
							block += '</div>'
						}
						block += '</div>'
					} else {
						block = `<div class="board-table">`
						for (let i = 0; i < item.data.content.length; i++) {
							const row = item.data.content[i]
							block += `<div class="board-table__row">`
							for (let j = 0; j < row.length; j++) {
								block += `<div class="board-table__row__cell">${row[j]}</div>`
							}
							block += '</div>'
						}
						block += '</div>'
					}
					break
				case 'video':
					if (item.data.file) {
						const videoPath = item.data.file.url
						console.log(videoPath)
						if (videoPath && videoPath !== '') {
							let align = 'left'
							if (item.data.center) {
								align = 'center'
							} else if (item.data.right) {
								align = 'right'
							}
							let size = 'large'
							if (item.data.middle) {
								size = 'middle'
							} else if (item.data.small) {
								size = 'small'
							}
							if (item.data.caption && item.data.caption !== '') {
								if (item.data.withBorder) {
									block = `<div class="board-video board-video--${align} board-video--${size} board-video--border"><video src="${videoPath}#t=0.001" controls controlsList="nodownload" playsinline="" oncontextmenu="return false;"></video><p class="board-video__caption">${item.data.caption}</p></div>`
								} else {
									block = `<div class="board-video board-video--${align} board-video--${size}"><video src="${videoPath}#t=0.001" controls controlsList="nodownload" playsinline="" oncontextmenu="return false;"></video><p class="board-video__caption">${item.data.caption}</p></div>`
								}
							} else {
								if (item.data.withBorder) {
									block = `<div class="board-video board-video--${align} board-video--${size} board-video--border"><video src="${videoPath}#t=0.001" controls controlsList="nodownload" playsinline="" oncontextmenu="return false;"></video></div>`
								} else {
									block = `<div class="board-video board-video--${align} board-video--${size}"><video src="${videoPath}#t=0.001" controls controlsList="nodownload" playsinline="" oncontextmenu="return false;"></video></div>`
								}
							}
						}
					}
					break
				case 'attaches':
					const extensionColor = this.EXTENSIONS[item.data.file.extension] || ''
					const extension = extensionColor !== '' ? item.data.file.extension : ''
					const fileIcon =
						extensionColor !== ''
							? '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40"><path d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"/></svg>'
							: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40"><g fill="#A8ACB8" fill-rule="evenodd"><path fill-rule="nonzero" d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"/><path d="M7 22h18v2H7zm0 4h18v2H7zm0 4h18v2H7z"/></g></svg>'
					let sizePrefix = 'KB'
					let formattedSize = Math.round(item.data.file.size / 100) / 10
					if (item.data.file.size.length >= 6) {
						sizePrefix = 'MB'
						formattedSize = Math.round(item.data.file.size / 100000) / 10
					}
					block = `
<div class="board-attaches">
	<a class="board-attaches__download-button" href="${item.data.file.url}" target="_blank" rel="nofollow noindex noreferrer">
		<div class="board-attaches__file-icon" data-extension="${extension}" style="color:${extensionColor}">${fileIcon}</div>
		<div class="board-attaches__file-info">
			<div class="board-attaches__file-info__title">${item.data.title}</div>
			<div class="board-attaches__file-info__size">${formattedSize} ${sizePrefix}</div>
		</div>
		<svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17">
			<path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path>
		</svg>
	</a>
</div>`
					break
				case 'alert':
					block = `<div class="board-alert board-alert--${item.data.alignment}"><div class="board-alert__inner board-alert__inner--${item.data.type} board-alert__inner--${item.data.display}">${item.data.message}</div></div>`
					break
				case 'code':
					block = `<pre class="board-pre">${item.data.code}</pre>`
					break
				case 'raw':
					block = item.data.html
					break
				case 'breakLine':
					block = `<div style="height: 30px;">&nbsp;</div>`
					break
				case 'delimiter':
					block = `<div style="padding: 15px 0;"><div style="border-top: ${item.data.width} ${item.data.type} ${item.data.color};"></div></div>`
					break
			}
			html += block
		}
		console.log(html)
		$('#pre').html(JSON.stringify(this._data, null, 2))
		$('#html').val(html)
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Editor
	 * @return {undefined}
	 */
	createDataSubmit() {
		console.log('Editor.createDataSubmit')
		this._editor
			.save()
			.then((outputData) => {
				this._data = outputData
				if (this._data.blocks.length === 0) {
					this._$e.addClass('is-invalid')
				} else {
					$('#data').val(JSON.stringify(this._data))
					this.createHTML()
				}
			})
			.catch((error) => {
				Common.showErrorModal('掲示板の作成でエラーが発生しました。')
			})
	}

	get EXTENSIONS() {
		console.log('Editor.EXTENSIONS')
		return {
			doc: '#3e74da',
			docx: '#3e74da',
			odt: '#3e74da',
			pdf: '#d47373',
			rtf: '#656ecd',
			tex: '#5a5a5b',
			txt: '#5a5a5b',
			pptx: '#e07066',
			ppt: '#e07066',
			mp3: '#eab456',
			mp4: '#f676a6',
			xls: '#3f9e64',
			xlsx: '#3f9e64',
			html: '#2988f0',
			htm: '#2988f0',
			png: '#f676a6',
			jpg: '#f67676',
			jpeg: '#f67676',
			gif: '#f6af76',
			zip: '#4f566f',
			rar: '#4f566f',
			exe: '#e26f6f',
			svg: '#bf5252',
			ai: '#df821c',
			psd: '#388ae5',
			dmg: '#e26f6f',
			json: '#2988f0',
			csv: '#3f9e64',
		}
	}
}
