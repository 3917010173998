/**
 * @file Form送信
 */
'use strict'
// utility class
import Common from '../../Utils/Common'
import FormBoard from './Custom/FormBoard'
import FormAPIUpload from './Custom/FormAPIUpload'
import FormJobInformation from './Custom/FormJobInformation'

/**
 * @classname Submit
 * @classdesc フォームサブミット
 */
export default class Submit {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('Submit.constructor')
		this._$e = $e
		this._controller = controller
		this._form = $e.attr('data-form')
		this._$form = $(this._form)
		this._process = $e.attr('data-process') || null
		this._timing = $e.attr('data-timing') || null
		this._submit = true
		this._validate = false
		if (this._process === 'true') {
			// 個別処理ファイルのアクティベート
			switch (this._form) {
				case '#form-board':
					this._processing = new FormBoard($e, controller)
					break
				case '#form-api':
					this._processing = new FormAPIUpload($e, controller)
					break
				case '#form-job-information':
					this._processing = new FormJobInformation($e, controller)
					break
			}
		}
		this._$e.on('click', (e) => {
			e.preventDefault()
			if (this._$e.hasClass('disabled')) return
			if (this._$e.prop('disabled')) return
			if (!$e.hasClass('clicked')) {
				$e.addClass('clicked')
				this._flg = false
				this.onClick()
			}
		})
		this._flg = true
		this._controller.on(
			'completeMultipleCheckAllPreProcessing',
			() => this._$form.attr('id') === this._controller._currnetMultipleCheckAllFormId && this.validate(),
		)
		this._controller.on('completePreProcessing', () => this.validate())
		this._controller.on('completeValidate', () => this.completeValidate())
		this._controller.on('completePostProcessing', () => this.addMinutes())
		this._controller.on('hasErrorPostProcessing', () => this.initialize())
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	onClick() {
		console.log('Submit.onClick')
		console.log(this._$e)
		console.log(this._form)
		this._controller._$form = this._$form
		//this.checkPreviewMode()
		if (this._process === 'true') {
			if (this._timing === 'pre' || this._timing === 'both') {
				console.log('前処理実施')
				this._processing.preProcessing(this._$form)
			} else {
				console.log('前処理SKIP')
				this.validate(this._$form)
			}
		} else {
			console.log('前処理SKIP')
			this.validate(this._$form)
		}
	}

	/**
	 * バリデーションチェック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	validate() {
		if (this._validate) return
		if (this._$form !== this._controller._$form) return
		this._validate = true
		console.log('Submit.validate')
		console.log('バリデーションチェック開始')
		this._controller.validate(this._$form)
	}

	/**
	 * バリデーション完了
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	completeValidate() {
		console.log('Submit.completeValidate')
		console.log('バリデーションチェック完了')
		setTimeout(() => {
			if (this._$form.find('.validate').hasClass('is-invalid')) this._submit = false
			if (this._$form.find('.validate-checkbox').hasClass('is-invalid')) this._submit = false
			if (this._$form !== this._controller._$form) return
			if (this._submit) {
				console.log('エラーチェック：エラーなし')
				this.postProcessing()
			} else {
				Common.showErrorModal('入力項目に誤りがあります')
				this._flg = true
				this.initialize()
			}
		}, 300)
	}

	/**
	 * 後処理
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	postProcessing() {
		console.log('Submit.postProcessing')
		console.log(`process: ${this._process}`)
		if (this._process === 'true') {
			if (this._timing === 'post' || this._timing === 'both') {
				console.log('後処理実施')
				this._processing.postProcessing(this._$form)
			} else {
				console.log('後処理SKIP')
				this.addMinutes()
			}
		} else {
			console.log('後処理SKIP')
			this.addMinutes()
		}
	}

	/**
	 * 分を追加する
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	addMinutes() {
		console.log('Submit.addMinutes')
		if (this._$form !== this._controller._$form) return
		console.log('Submit.addMinutes::form id:' + this._$form.attr('id'))
		$('input[data-time=true]').each((idx, e) => {
			let val = $(e).val()
			if (val.length === 16) {
				val += ':00'
				console.log(val)
				$(this).val(val)
			}
		})
		this.submit()
	}

	/**
	 * submit
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	submit() {
		console.log('Submit.submit')
		$('.ignore-validate').removeAttr('name')
		console.log('サブミット')
		console.log(this._$form)
		setTimeout(() => {
			Common.submit(this._$form)
		}, 200)
		if (this._$form.attr('action') === '#' || this._$form.attr('action') === '' || this._$form.attr('action') === '__TBA__') {
			setTimeout(() => this.initialize(), 1000)
		}
		if(this._$form.find('[name="mode-preview"]').length){
			setTimeout(() => {
				this._$e.removeClass('clicked')
				this._$form.removeAttr('target')
				$('[name="mode-preview"]').remove()
			}, 1000)
		}
	}

	/**
	 * 初期化
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	initialize() {
		console.log('Submit.initialize')
		this._submit = true
		this._validate = false
		this._controller._$form = null
		this._flg = true
		this._$e.removeClass('clicked')
	}

	/**
	 * プレビューモードかどうか確認
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	checkPreviewMode() {
		console.log('Submit.checkPreviewMode')
		const previewInput = $('[name="mode-preview"]')
		if (previewInput.length) {
			if (previewInput.val() === 'pre') previewInput.val('post')
			else if (previewInput.val() === 'post') {
				this._$form.removeAttr('target')
				previewInput.remove()
			}
		}
	}
}
