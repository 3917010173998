/**
 * @file 確認モーダルを出す
 */
'use strict'

/**
 * @classname OpenConfirmModal
 * @classdesc 確認モーダルを出す。IDをdata属性に持たせることでモーダルないのフォームにinput要素を追加する
 * data-form-id :: inputを追加するform要素のIDを追加する
 * data-input-〇〇 :: 〇〇は追加するinputのname属性、data属性の値には追加するinputのvalueが来るようにする（複数可能）
 */
export default class OpenConfirmModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('OpenConfirmModal.constructor')
		this._$e = $e
		this._$dataAttribute = this._$e.data()
		this._$targetForm = $(`#${this._$e.data('form-id')}`)

		this._$e.on('click', (e) => this.onClick(e))
	}

	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof OpenConfirmModal
	 * @return {undefined}
	 */
	onClick() {
		console.log('OpenConfirmModal.onClick', this._$dataAttribute)
		const inputNameArray = []
		this._$targetForm.find('.input-form-area').children().remove()
		Object.keys(this._$dataAttribute)?.forEach((key) => {
			console.log(key)
			if (key.substring(0, 5) === 'input') {
				inputNameArray.push(key.substring(5))
			}
		})
		for (let i = 0; i < inputNameArray.length; i++) {
			// $.data()でとるとキャメルケースになるため、ケバブケースになるように変換処理入れている
			const input = `<input type="hidden" name="${this.camelToKebab(inputNameArray[i])}" value="${this._$e.data('input' + inputNameArray[i])}">`
			this._$targetForm.find('.input-form-area').append(input)
		}
	}
	/**
	 * camelToKebab
	 *
	 * @memberof OpenConfirmModal
	 * @return {undefined}
	 */
	camelToKebab(str) {
		console.log('OpenConfirmModal.camelToKebab')
		return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
	}
}
