// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdx-list {
    margin: 0;
    padding-left: 40px;
    outline: none;
}

    .cdx-list__item {
        padding: 5.5px 0 5.5px 3px;
        line-height: 1.6em;
    }

    .cdx-list--unordered {
        list-style: disc;
    }

    .cdx-list--ordered {
        list-style: decimal;
    }

    .cdx-list-settings {
        display: flex;
    }

    .cdx-list-settings .cdx-settings-button {
            width: 50%;
        }
`, "",{"version":3,"sources":["webpack://./mock/src/js/Libs/editorjs/list/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;IAClB,aAAa;AAsBjB;;IApBI;QACI,0BAA0B;QAC1B,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,aAAa;IAKjB;;IAHI;YACI,UAAU;QACd","sourcesContent":[".cdx-list {\n    margin: 0;\n    padding-left: 40px;\n    outline: none;\n\n    &__item {\n        padding: 5.5px 0 5.5px 3px;\n        line-height: 1.6em;\n    }\n\n    &--unordered {\n        list-style: disc;\n    }\n\n    &--ordered {\n        list-style: decimal;\n    }\n\n    &-settings {\n        display: flex;\n\n        .cdx-settings-button {\n            width: 50%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
