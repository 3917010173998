/**
 * @file 画像ダウンロード禁止
 */
'use strict';

/**
 * @classname ImageNoDownload
 * @classdesc 画像ダウンロード禁止
 */
export default class ImageNoDownload {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ImageNoDownload.constructor');
		if(!$e.attr('onerror')){
			$e.attr('onmousedown', 'return false;');
			$e.attr('oncontextmenu', 'return false;');
		}
		$('.image-box-detail-photo').on('contextmenu', () => {
			return false;
		});
		$('.image-box-author-photo').on('contextmenu', () => {
			return false;
		});
	};
}
