/**
 * @file DeleteItem
 */
"use strict";

/**
 * @classname DeleteItem
 * @classdesc deleteItem
 */
export default class DeleteItem {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log("DeleteItem.constructor");
		this._name = $e.attr("data-name");
		this.value = $e.data("value");
		this._$deleteForm = $("#form-delete");
		$e.on("click", () => this.onClick());
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof DeleteItem
	 * @return {undefined}
	 */
	onClick() {
		console.log("DeleteItem.onClick");
		$("#modal-delete span.name").text(this._name);
		const _$inputs = this._$deleteForm.find("input[type='hidden']");
		_$inputs.each((idx, input) => {
			if ($(input).attr("name") === "_token") return;
			$(input).val(this.value);
		});
	}
}
