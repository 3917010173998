/**
 * @file テンプレート
 */

'use strict';
import Common from '../Utils/Common';

let instance = null;

/**
 * @class Template
 * @classdesc テンプレート
 */
export default class Template {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		instance = this;
		return instance;
	};


	/**
	 * @memberof Template
	 * @return {string}
	 */
	static Operator(idx) {
		console.log(`Template.Operator`);
		const no = parseInt(idx) + 1;
		return `
<li class="list-item list-item-additional list-item-operator" data-no="${no}">
	<a class="btn btn-add btn-success" data-add-type="operator" data-base-object=".list-item"><i class="fas fa-plus"></i></a>
	<a class="btn btn-delete btn-danger" data-base-object=".list-item"><i class="fas fa-minus"></i></a>
	<div class="row form-group">
		<div class="col-md-3 col-form-label">操作担当者<span class="badge badge-danger float-right ml-1">必須</span></div>
		<div class="col-md-3">
			<div class="input-select select2-wrap">
				<select class="validate select2 input-select-select member_id" name="member_id[${idx}]" data-validate="empty">
				</select>
			</div>
		</div>
	</div>
	<div class="row form-group">
		<div class="col-md-3 col-form-label">権限<span class="badge badge-danger float-right ml-1">必須</span></div>
		<div class="col-md-4">
			<div class="input-select">
				<select class="validate input-select-select" name="operator_role[${idx}]" data-validate="empty">
					<option value="1">編集者（メールマガジン送付可）</option>
					<option value="2">閲覧者（メールマガジン送付可）</option>
					<option value="3">閲覧者（メールマガジン送付不可）</option>
					<option value="4">メールマガジン送付担当者</option>
				</select>
			</div>
		</div>
	</div>
</li>`;
	};

	/**
	 * @memberof Template
	 * @return {string}
	 */
	static errorModal(errorText = '入力項目に誤りがあります。') {
		console.log(`Template.errorModal`)
		return `
<div class="modal modal-error fade show" id="modal-error" tabindex="-1" style="padding-right: 15px; display: block;" aria-modal="true" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header bg-danger text-white">
				<h5 class="modal-title">エラー</h5>
				<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="tac pt20 mb30">
					<i class="fas fa-exclamation-triangle text-danger" style="font-size: 60px;"></i>			
				</div>
				<div>${errorText}</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
			</div>
		</div>
	</div>
</div>`
	}


	/**
	 * @memberof Template
	 * @return {string}
	 */
	static completeModal(message = '処理が正常に終了しました。') {
		return `
<div class="modal modal-success fade show" id="modal-success" tabindex="-1" style="padding-right: 15px; display: block;" aria-modal="true" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header bg-success text-white">
				<h5 class="modal-title">完了</h5>
				<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="tac pt20 mb30">
					<i class="fas fa-check text-success" style="font-size: 60px;"></i>			
				</div>
				<div>${message}</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
			</div>
		</div>
	</div>
</div>`
	}
}
