/**
 * @file ファイルアップロード
 */
"use strict";

/**
 * @classname PartsCategoryGroupSelect
 * @classdesc パーツのカテゴリーとグループのセレクトボックスの連動
 */
export default class PartsCategoryGroupSelect {
	/**
	 * @constructor
	 * @param {jQuery} $e - form jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log("PartsCategoryGroupSelect.constructor");
		this._$e = $e;
		this.stepData = [];
		this.currentStepData;
		this._$categorySelectBox = $e.find("#category");
		if (!this._$categorySelectBox) return;
		this._$groupSelectBox = $e.find("#group");
		this.partsCode = $e.find("#parts_code").val();
		(async () => {
			const res = await fetch(window.const.STATIC_URL.PARTS_CATEGORY_GROUP);
			this.stepData = await res.json();
			this._$categorySelectBox.on("change", (e) => this.onChange(e));
			this.stepData.forEach((step) => {
				if (step.parts_code === this.partsCode) {
					this.currentStepData = step;
				}
			});
		})();
	}

	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof PartsCategoryGroupSelect
	 * @return {undefined}
	 */
	onChange(e) {
		console.log("PartsCategoryGroupSelect.onChange", e);
		if (!e.target.value) {
			this._$groupSelectBox.prop("disabled", true);
			this._$groupSelectBox.children(":first-child").siblings().remove();
			this._$groupSelectBox.children(":first-child").text("カテゴリーを選択してください");
			return;
		}
		this.currentStepData.categories.forEach((category) => {
			if (category.id !== Number(e.target.value)) return;
			if (!category.groups) {
				this._$groupSelectBox.prop("disabled", true);
				this._$groupSelectBox.children(":first-child").siblings().remove();
				this._$groupSelectBox
					.children(":first-child")
					.text("このカテゴリーにはグループはありません");
				return;
			}

			this._$groupSelectBox.children(":first-child").siblings().remove();
			this._$groupSelectBox.children(":first-child").text("選択してください");
			this._$groupSelectBox.append(
				category.groups
					.map(
						(group) =>
							`
                        <option value="${group.id}">${group.name}</option>
                    `
					)
					.join("")
			);
			this._$groupSelectBox.prop("disabled", false);
		});
	}
}
