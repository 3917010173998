/**
 * @file サムネイル回転
 */
'use strict';

/**
 * @classname ChangeRotate
 * @classdesc サムネイル回転
 */
export default class ChangeRotate {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		$e.on('change', () => this.onChange());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof ChangeRotate
	 * @return {undefined}
	 */
	onChange() {
		console.log(`${this.constructor.name}.onChange`);
		const $img = $('.block-photo-wrap img');
		const width = $img.width();
		const height = $img.height();
		if(this._$e.val() === '90' || this._$e.val() === '270'){
			if(width === height){
				$img.attr('style', `transform:rotate(${this._$e.val()}deg);`);
			}else if(width > height){
				const scale = height / width;
				$img.attr('style', `transform:scale(${scale}) rotate(${this._$e.val()}deg);`);
			}else{
				const scale = width / height;
				const wrapperHeight = Math.ceil(width * scale);
				const margin = (height - wrapperHeight) / -2;
				$img.attr('style', `transform:scale(${scale}) rotate(${this._$e.val()}deg);margin-top:${margin}px;margin-bottom:${margin}px;`);
			}
		}else{
			$img.attr('style', `transform:rotate(${this._$e.val()}deg);`);
		}
	};
}
