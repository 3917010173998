/**
 * @file 
 */
'use strict'

/**
 * @classname BtnDeleteItems
 * @classdesc 納期一括削除
 */
export default class BtnDeleteItems {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn-delete-items
	 */
	constructor($e) {
		console.log('BtnDeleteItems.constructor')
		this._$e = $e
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof BtnDeleteItems
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnDeleteItems.onClick')
		const ids = $('.input-delivery-date').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')) {
				return $e.attr('data-id')
			}
		}).get().join(',');
		console.log(ids);
		$('.input-delete-items').val(ids);
		$('#delete_items').submit();
	}
}
