/**
 * @file FormJobInformation
 */
'use strict'

import FormAPIUpload from './FormAPIUpload'

/**
 * @classname FormJobInformation
 * @classdesc
 */
export default class FormJobInformation {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		this._controller = controller
		this.FormAPIUploadClass = new FormAPIUpload($e, controller)
	}

	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormJobInformation
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`)
		// バリデーション前に行う処理を記載（終了）
		this._controller.completePreProcessing($form)
	}

	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormJobInformation
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log(`${this.constructor.name}.postProcessing`)
		// 送信するフォームのエリアをリセット
		const $formArea = $form.find('.multiple-campaign-form-area')
		$formArea.children().remove()

		// マルチプルフォームは配列で送信するためにバリデーション後に形を変えている。
		const $multipleSelectForm = $form.find('.recommend-select-form')
		const selectFormValue = $multipleSelectForm.val()
		if (Array.isArray(selectFormValue)) {
			for (let i = 0; i < selectFormValue.length; i++) {
				const name = $multipleSelectForm.attr('data-name')
				const input = `<input type="hidden" name="${name}" value="${selectFormValue[i]}">`
				$formArea.append(input)
			}
		}
		this.FormAPIUploadClass.postProcessing($form)
	}
}
