/**
 * @file パスワードの表示・非表示切り替え
 */
'use strict';

/**
 * @classname TogglePassword
 * @classdesc パスワードの表示・非表示切り替え
 */
export default class TogglePassword {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-toggle-password')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._target = this._$e.closest('.form-group').find('.input-password-user');
		this._showState = false;
		this._$e.on('click', () => this.onClick());
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof TogglePassword
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		this._target.attr('type', this._showState ? 'password' : 'text');
		this._$e.closest('.form-group').toggleClass('is-show-password');
		this._showState = !this._showState;
	}
}
