/**
 * @file select2
 */
'use strict';
import { select2 } from '../../Libs/select2.min'

/**
 * @classname FormSelect2
 * @classdesc select2
 */
export default class Select2 {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Select2.constructor');
		if($e.hasClass('co_author')){
			console.log('co_author');
			$e.select2({
				placeholder: '社員名で検索'
			});
			$e.on('select2:open', () => {
				$('.select2-search.select2-search--dropdown input').focus().select();
			});
		}else{
			$e.select2();
		}
		$('.btn-select2-clear').on('click', () => {
			$e.val('').trigger('change');
		});
	};
}
