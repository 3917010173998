/**
 * @file 離脱防止アラート
 */
'use strict'

/**
 * @classname PreventExit
 * @classdesc 離脱防止アラート
 */
export default class PreventExit {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('PreventExit.constructor')
		this._$e = $e
		this._controller = controller

		this._$input = $e.find('input')
		this._isActive = false

		this._$input.each((idx, e) => {
			const $this = $(e)
			const _type = $(e).attr('type')

			if ($this.attr('data-datepicker') === 'true') {
				$this.on('blur', () => this.onChange())
			} else if (_type ==='checkbox' || _type === 'radio' ) {
				$this.on('click', () => this.onChange())
			} else {
				$this.on('change', () => this.onChange())
			}
		})

		this._$e.find('textarea').each((idx, e) => {
			const $this = $(e)
			$this.on('blur', () => this.onChange())
		})

		this._$e.find('select').each((idx, e) => {
			const $this = $(e)
			$this.on('change', () => this.onChange())
		})

		this._$e.find('#codex-editor').each((idx, e) => {
			console.log('Editorjsがあります')
			const $this = $(e)
			$this.on('DOMSubtreeModified', () => this.onChange())
		})

		window.onbeforeunload = (event) => {
			console.log('window.onbeforeunload:' + this._isActive) 
			if (!this._isActive) {
				return;
			}
			event.returnValue = 'ページを離れようとしています。よろしいですか？'
		}
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof PreventExit
	 * @return {undefined}
	 */
	onChange() {
		console.log('PreventExit.onChange')
		this._isActive = true
	}
}
