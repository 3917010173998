/**
 * @file FormModel Controller
 */

'use strict';
import Listener from '../Utils/Listener';
import Common from '../Utils/Common';

let instance = null;

/**
 * @classname FormModel
 * @classdesc Basic Model
 */
export default class FormModel {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		this._listener = new Listener();
		this._jqXHR = null;
		this._post = false;
		this._data = null;
		this._message = null;
		this._code = null;

		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		instance = this;
		return instance;
	};


	/**
	 * 「読んだよ！」ボタンのカウントを増やす
	 * 
	 * @memberof BasicModel
	 * @param {integer} articleId - 記事ID
	 * @return {undefined}
	 */
	incrementReactionCount(articleId) {
		if (this._post) return;
		console.log(`FormModel.incrementReactionCount`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('id', articleId);
		let params = {
			data: formData,
			method: 'POST',
			dataType: 'json',
			url: window.const.API_URL.INCREMENT_REACTION_COUNT,
			processData: false,
			contentType: false
		};
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onIncrementReactionCountComplete.bind(this));
	};


	/**
	 * 「読んだよ！」ボタンのカウントを増やす ajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onIncrementReactionCountComplete(data, textStatus) {
		console.log('BasicModel.onIncrementReactionCountComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(this._message);
			} else {
				this._post = false;
				this._listener.trigger('incrementReactionCountComplete');
			}
		} else {
			if (data.status === 401) {
				Common.show401Modal();
				return;
			}
			this._post = false;
			this._code = 9999;
			this._message = `（${data.status}）<br>通信エラーが発生しました。<br>再度お試しください。`;
			Common.showErrorModal(this._message);
			console.log(`ajax error (onIncrementReactionCountComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * 検索条件保存
	 * 
	 * @memberof BasicModel
	 * @param {string} searchType - 検索タイプ
	 * @param {string} filterName - 検索条件の保存名
	 * @param {string} searchUrl - 検索URL
	 * @return {undefined}
	 */
	saveFilter(searchType, filterName, searchUrl) {
		if (this._post) return;
		console.log(`BasicModel.saveFilter`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('search_page_number', searchType);
		formData.append('filter_name', filterName);
		formData.append('search_url', searchUrl);
		let params = {
			data: formData,
			method: 'POST',
			dataType: 'json',
			url: window.const.API_URL.SAVE_FILTER,
			processData: false,
			contentType: false
		};
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onSaveFilterComplete.bind(this));
	};


	/**
	 * 検索条件保存完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onSaveFilterComplete(data, textStatus) {
		console.log('BasicModel.onSaveFilterComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(this._message);
			} else {
				this._post = false;
				this._listener.trigger('saveFilterComplete');
			}
		} else {
			if (data.status === 401) {
				Common.show401Modal();
				return;
			}
			this._post = false;
			this._code = 9999;
			this._message = `（${data.status}）<br>通信エラーが発生しました。<br>再度お試しください。`;
			Common.showErrorModal(this._message);
			console.log(`ajax error (onSaveFilterComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * 保存済検索条件リストの取得
	 * 
	 * @memberof BasicModel
	 * @param {string} searchType - 検索タイプ
	 * @return {undefined}
	 */
	getSavedFilters(searchType) {
		if (this._post) return;
		console.log(`BasicModel.getSavedFilters`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('search_page_number', searchType);
		let params = {
			data: formData,
			method: 'POST',
			dataType: 'json',
			url: window.const.API_URL.GET_SAVED_FILTERS,
			processData: false,
			contentType: false
		};
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetSavedFiltersComplete.bind(this));
	};


	/**
	 * 保存済検索条件リストの取得完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetSavedFiltersComplete(data, textStatus) {
		console.log('BasicModel.onGetSavedFiltersComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(this._message);
			} else {
				this._post = false;
				this._listener.trigger('getSavedFiltersComplete');
			}
		} else {
			if (data.status === 401) {
				Common.show401Modal();
				return;
			}
			this._post = false;
			this._code = 9999;
			this._message = `（${data.status}）<br>通信エラーが発生しました。<br>再度お試しください。`;
			Common.showErrorModal(this._message);
			console.log(`ajax error (onSaveFilterComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * 保存済検索条件削除
	 * 
	 * @memberof BasicModel
	 * @param {number} filterId - フィルターID
	 * @return {undefined}
	 */
	deleteFilter(filterId) {
		if (this._post) return;
		console.log(`BasicModel.deleteFilter`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('id', filterId);
		let params = {
			data: formData,
			method: 'POST',
			dataType: 'json',
			url: window.const.API_URL.DELETE_FILTER,
			processData: false,
			contentType: false
		};
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onDeleteFilterComplete.bind(this));
	};


	/**
	 * 保存済検索条件削除完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onDeleteFilterComplete(data, textStatus) {
		console.log('BasicModel.onDeleteFilterComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(this._message);
			} else {
				this._post = false;
				this._listener.trigger('deleteFilterComplete');
			}
		} else {
			if (data.status === 401) {
				Common.show401Modal();
				return;
			}
			this._post = false;
			this._code = 9999;
			this._message = `（${data.status}）<br>通信エラーが発生しました。<br>再度お試しください。`;
			Common.showErrorModal(this._message);
			console.log(`ajax error (onDeleteFilterComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
