/**
 * @file 画像拡大
 */
'use strict';

/**
 * @classname Lightbox
 * @classdesc 画像拡大
 */
export default class Lightbox {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`Lightbox.constructor`);
		$('body').on('click', '.lightbox-trigger', (e) => this.onClick(e));
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof Lightbox
	 * @return {undefined}
	 */
	onClick(e) {
		console.log(`Lightbox.onClick`);
		const $e = $(e.currentTarget);
		if($e.hasClass('disabled')) return;
		const $img = $e.find('img');
		const src = $img.attr('src');
		const rotate = parseInt($img.attr('data-rotate'));
		const lightbox =
`<div class="lightbox-bg">
	<div class="lightbox">
		<img src="${src}" class="lightbox-image" oncontextmenu="return false;" alt="">
		<button type="button" class="lightbox-close">&times</button>
	</div>
</div>`;
		$('body').append(lightbox);
		console.log(`rotate:${rotate}`);
		const $image = $('.lightbox-image');
		if(rotate === 90 || rotate === 270){
			const width = $image.width();
			const height = $image.height();
			const scale = width / height;
			const wrapperHeight = Math.ceil(width * scale);
			const margin = (height - wrapperHeight) / -2;
			const style = `transform:rotate(${rotate}deg) scale(${scale});margin-top:${margin}px;margin-bottom:${margin}px;`;
			$image.attr('style', style);
		}else if(rotate === 180){
			$image.css('transform', 'rotate(180deg)');
		}
		$('.lightbox-image').on('click', e => {
			e.preventDefault();
			e.stopPropagation();
		});
		$('.lightbox-close, .lightbox-bg').on('click', () => this.closeLightbox());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Lightbox
	 * @return {undefined}
	 */
	closeLightbox() {
		console.log(`Lightbox.closeLightbox`);
		$('.lightbox-bg').fadeOut();
		setTimeout(() => {
			$('.lightbox-bg').remove();
		},500);
	};
}
