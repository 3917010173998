/**
 * @file タブ
 */
'use strict'

/**
 * @classname Tab
 * @classdesc タブ
 */
export default class Tab {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Tab.constructor')
		this._$e = $e
		this._$target = $(`#${$e.attr('aria-controls')}`)
		this._$input = this._$target.find('.validate')
		this._$tabContentInnerInput = $('.tab-pane').find('.validate')
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof Tab
	 * @return {undefined}
	 */
	onClick() {
		console.log('Tab.onClick')
		// 通常のタブの切り替えはcoreUIが行なっているので、捜査内容はvalidateの管理のみになっている
		this._$tabContentInnerInput.addClass('ignore-validate').removeClass('is-invalid')
		this._$input.removeClass('ignore-validate')
	}
}
