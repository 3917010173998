/**
 * @file FormBoard
 */
'use strict'

/**
 * @classname FormBoard
 * @classdesc
 */
export default class FormBoard {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	 constructor($e, controller){
		this._$e = $e;
		this._controller = controller
	}

	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`)
		// バリデーション前に行う処理を記載（終了）

		// プレビューの時
		// プレビュー時のform actionはすでに指定済み
		if($form.find('[name="mode-preview"]').length){
			// プレビューの時はバリデーションを飛ばす
			this._controller.createHtml(false)
			this._controller.completePostProcessing($form);
			return;
		// 通常の保存処理は、保存ボタンのdata-actionでform actionを再指定する
		}else {
			this._controller.createHtml(true)
			const action = this._$e.attr('data-action');
			$form.attr('action', action);
			this._controller.completePreProcessing($form)
		}
	}


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log(`${this.constructor.name}.postProcessing`)
		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form)
	}
}
