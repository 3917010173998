/**
 * @file 案件詳細画像登録(multiple)
 */
'use strict'

/**
 * @classname UploadPhotoMultiple
 * @classdesc 案件詳細画像登録(multiple)
 */
export default class UploadPhotoMultiple {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('UploadPhotoMultiple.constructor')
		this._$e = $e
		this._$input = this._$e.find('input[type=file]')
		this._$previewArea = this._$e.find('.preview-area')

		this._$input.on('change', (e) => this.onChange(e))
	}

	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof UploadPhotoMultiple
	 * @return {undefined}
	 */
	onChange(e) {
		console.log('UploadPhotoMultiple.onChange', e)
		// onload内で使うのでthisをbindするために変数で保持する
		const $previewArea = this._$previewArea
		const onDeletePhoto = this.onDeletePhoto
		const input = this._$input

		const files = e.target.files
		const maxMB = window.const.FILE.MAX_MB
		const maxSize = maxMB * 1024 * 1024
		//fileの値は空ではなくなるはず
		if (files.length) {
			const areaElement = `
							<div class="added-photo relative border flex" style="gap:10px">
							<button class="btn btn-delete-photo btn-delete-regist-photo" type="button">×</button>
							</div>`
			$previewArea.append(areaElement)
			const clone = input.clone().removeClass().addClass('uploaded').css('display', 'none').attr('name', input.attr('data-name'))
			$previewArea.find('.added-photo').last().append(clone)
			$previewArea
				.find('.btn-delete-regist-photo')
				.last()
				.on('click', (e) => onDeletePhoto(e))

			for (let i = 0; i < files.length; i++) {
				const image_ = files[i]

				//添付されたのが本当に画像かどうか、ファイル名と、ファイルタイプを正規表現で検証する
				if (!/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(image_.name) || !/(jpg|jpeg|png|gif)$/.test(image_.type)) {
					alert('JPG、GIF、PNGファイルの画像を添付してください。')
					break
					//添付された画像ファイルが１M以下か検証する
				} else if (maxSize < image_.size) {
					alert(`${maxMB}MB以下の画像を添付してください。`)
					break
				} else {
					//window.FileReaderに対応しているブラウザどうか
					if (window.FileReader) {
						//FileReaderをインスタンス化
						const reader_ = new FileReader()
						//添付ファイルの読み込みが成功したときに実行されるイベント（成功時のみ）
						//一旦飛ばしてreader_ .readAsDataURLが先に動く
						reader_.onload = function () {
							//Data URI Schemeをimgタグのsrcにいれてリアルタイムに添付画像を描画する
							const element = `
							<div style="max-height:100px;max-width:100px;display:inline-block;height:100%;" class="image-box">
							<img class="image" style="height:100%;width:100%;object-fit:contain" src="${reader_.result}" alt="">
							</div>
							`
							$previewArea.find('.added-photo').last().append(element)
						}
						//Data URI Schemeを取得する
						reader_.readAsDataURL(image_)
					}
				}
			}
		}
	}

	/**
	 * 画像の削除
	 *
	 * @memberof UploadPhotoMultiple
	 * @return {undefined}
	 */
	onDeletePhoto(e) {
		console.log('UploadPhotoMultiple.onDeletePhoto', e.target)
		$(e.target).closest('.added-photo').remove()
	}
}
