/**
 * @file Alert Close
 */
'use strict'

/**
 * @classname CloseAlert
 * @classdesc モダール内容書き換え
 */
export default class CloseAlert {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CloseAlert.constructor')
		this._$e = $e
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof CloseAlert
	 * @return {undefined}
	 */
	onClick() {
		console.log('CloseAlert.onClick')
		this._$e.closest('.alert').remove()
	}
}
