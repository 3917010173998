/**
 * @file 対象フォームをコピーして増やす
 */
'use strict'

import ApplyClass from '../../Utils/ApplyClass'
import Validate from '../../View/Form/Validate'
import CheckActivation from '../../View/Form/CheckActivation'
import Accordion from '../../View/Basic/Accordion'
import Form from '../../Controller/Form'
import UploadPhoto from '../../View/Form/UploadPhoto'
import Select2 from '../../View/Form/Select2'

/**
 * @classname AddTemplate
 * @classdesc 対象フォームをコピーして増やす
 */
export default class AddTemplate {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-toggle-password')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this.FormController = new Form()
		this._$e = $e
		this._$trigger = this._$e.find('[data-template-area-type="trigger"]')
		this._$area = this._$e.find('[data-template-area-type="area"]')
		this._$copyEl = this.createCopyElement()
		// 初期表示時にあるの増やしてあるformの数分countを増やしておく、クリックされたら+1されるため初期値は1減らしとく
		this.createdCount = this._$area.children().length - 1
		this._$trigger.on('click', () => this.onClick())
		const $delBtn = this._$e.find('[data-template-area-type="delete"]')
		$delBtn.on('click', (e) => this.onClickDelete(e))
	}

	/**
	 * onClickのコールバック
	 *
	 * @memberof AddTemplate
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`)
		const actionCount = this.createdCount + 1
		this.createdCount = actionCount
		const cloneElement = this._$copyEl.clone()
		this.replaceIdAndLabel(cloneElement, actionCount)
		this.replaceAttribute(cloneElement, actionCount)

		this._$area.append(cloneElement)

		// 追加した要素のみを指定したいので last()をつけてる
		const $delBtn = this._$e.find('[data-template-area-type="delete"]').last()
		const $addedElement = this._$e.find('[data-template-area-type="delete-target"]').last()
		$delBtn.on('click', (e) => this.onClickDelete(e))
		this.applyClass($addedElement)
	}

	/**
	 * コピーを作成
	 *
	 * @memberof AddTemplate
	 * @return {undefined}
	 */
	createCopyElement() {
		console.log(`${this.constructor.name}.createCopyElement`)
		const $copyElement = this._$area.children().first().clone()
		$copyElement.find('[data-template-area-type="delete"]').removeClass('hidden')

		// アクセス項目はhiddenを外す
		if (this._$e.attr('id') === 'how-access-form-wrapper') {
			$copyElement.removeClass('hidden')
		}

		// input要素の初期化
		$copyElement.find('input, select, textarea').each((index, el) => {
			const $el = $(el)
			const inputType = $el.attr('type')
			// コピーした時にラジオボタンを初期選択させる
			if (inputType === 'radio' && $el.attr('data-default') == 'true') {
				$el.prop('checked', true)
			}
			// コピーした時に入力されていた内容をリセット
			if (inputType !== 'checkbox' && inputType !== 'radio') {
				$el.val('')
			}
			$el.attr('checked', false)
			$el.find('option').attr('selected', false)

			// select2の場合、そのままコピーするのではなくHTMLを置き換える
			if($el.hasClass('select2')){
				const select2_id = $el.attr('data-add-select2-id')
				if(select2_id === '') return
				let $copySelect2
					switch (select2_id) {
						// 地域施設特集
						case 'local':
							$copySelect2 = $(`<div class="row form-group"><div class="col-md-3 col-form-label">職種名<span class="badge badge-success ml-4">任意</span></div><div class="col-8">
							<div class="input-select select2-wrap"><select class="validate select2 input-select" name="occupation_group[][occupation]" data-validate="" data-add-select2-id="local"></select></div></div></div>`)
							break;
					}
				let options = []
				$el.find('option, optgroup').each((index, el) => {
					const $this = $(el)
					// そのまま$elを入れると、select2独特の属性が付いてしまうのでそうならないため、個別でvalueとtext取ってきてる
					if($this.is('option')) {
						const value = $this.val()
						const text = $this.text()
						options.push(`<option value="${value}">${text}</option>`)
					}else if($this.is('optgroup')){
						const label = $this.attr('label')
						options.push(`<optgroup label="${label}"></optgroup>`)
					}
				})
				$copySelect2.find('.select2').append(options)
				$el.closest('.form-group').replaceWith($copySelect2)
			}

			// チェックボックスで活性、非活性を管理しているform要素の初期化
			const activationInput = $el.data('check-activation-type')
			console.log('activationInput', activationInput)
			if (activationInput === 'target') {
				$el.attr('disabled', true)
			}

			// アクセス項目は.ignore-validateを外す
			if (this._$e.attr('id') === 'how-access-form-wrapper') {
				$el.removeClass('ignore-validate')
			}
		})
		// 登録されてる画像削除
		$copyElement.find('.display-area').children().remove()

		return $copyElement
	}

	/**
	 * name属性の書き換え
	 *
	 * @memberof AddTemplate
	 * @param {jQuery} $cloneElement
	 * @param {int} actionCount
	 * @return {undefined}
	 */
	replaceAttribute($cloneElement, actionCount) {
		console.log(`${this.constructor.name}.replaceIdAndLabel`, $cloneElement.length)
		$cloneElement.find(`input[name], input[data-name], select[name], textarea[name]`).each((index, element) => {
			const $el = $(element)
			const type = $el.attr('type')
			// 画像データの場合はdata-name属性をインクリメントする
			let nameAttr
			type === 'file' ? nameAttr = $el.attr('data-name') : nameAttr = $el.attr('name')
			
			console.log('取得したname属性確認', nameAttr)
			console.log('取得したtype属性確認', type)

			// name属性は aaa[X][bbb][C] のような形を取るので、配列の番号(X)の前と後ろのname属性だけ取る処理をしてる
			const nameBefore = nameAttr.split('[')[0]
			const nameAfter = nameAttr
				.split(']')
				.filter((el, index) => index != 0)
				.join(']')
			const newNameAttr = `${nameBefore}[${this.createdCount}]${nameAfter}`

			if(type === 'file'){
				$el.attr('data-name', newNameAttr)
			}else {
				$el.attr('name', newNameAttr)
			}
		})
	}

	/**
	 * idとlabelのfor属性書き換え
	 *
	 * @memberof AddTemplate
	 * @param {jQuery} $cloneElement
	 * @param {int} actionCount
	 * @return {undefined}
	 */
	replaceIdAndLabel($cloneElement, actionCount) {
		console.log(`${this.constructor.name}.replaceIdAndLabel`, $cloneElement.length)
		$cloneElement.find(`[id]`).each((index, element) => {
			const $el = $(element)
			const oldId = $el.attr('id')
			const $forLabel = $cloneElement.find(`[for="${oldId}"]`)
			const newId = oldId + '-' + actionCount
			$el.attr('id', newId)
			$forLabel.attr('for', newId)
		})
	}

	/**
	 * onClickのコールバック
	 *
	 * @memberof AddTemplate
	 * @return {undefined}
	 */
	onClickDelete(e) {
		console.log(`${this.constructor.name}.onClickDelete`, e.target, $(e.target))
		const deleteTarget = $(e.target).closest('[data-template-area-type="delete-target"]')
		deleteTarget.remove()
		console.log(deleteTarget.length)
	}

	/**
	 * 追加したテンプレートのパーツにapplyClassする
	 *
	 * @memberof AddTemplate
	 * @return {undefined}
	 */
	applyClass($addedElement) {
		console.log(`${this.constructor.name}.applyClass`)
		// バリデート
		$addedElement.find('.validate').each((idx, e) => {
			const $e = $(e)
			ApplyClass.apply(Validate, $e, [$e, this.FormController])
		})
		// チェックボックスによる活性化の監視
		$addedElement.find('[data-check-activation-type="wrap"]').each((idx, e) => {
			const $e = $(e)
			ApplyClass.apply(CheckActivation, $e, [$e])
		})
		// ファイルアップロードの監視
		$addedElement.find('.block-file-upload').each((idx, e) => {
			const $e = $(e)
			ApplyClass.apply(UploadPhoto, $e, [$e, this.FormController])
		})
		// Accordion
		$addedElement.find('[data-accordion-type="wrap"]').each((idx, e) => {
			const $e = $(e)
			ApplyClass.apply(Accordion, $e, [$e])
		})
		// select2
		$addedElement.find('.select2').each((idx, e) => {
			const $e = $(e)
			ApplyClass.apply(Select2, $e, [$e, this.FormController])
		})
	}
}
