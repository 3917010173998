/**
 * @file Accordion
 */
'use strict'

/**
 * @classname Accordion
 * @classdesc Accordion
 */
export default class Accordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$emitter = this._$e.find('[data-accordion-type="emitter"]').first()
		this._$target = this._$e.find('[data-accordion-type="target"]').first()
		this._$icon = this._$emitter.find('i.fa-plus').first() || null
		this._$icon2 = this._$emitter.find('i.fa-chevron-down').first() || null
		this._$icon3 = this._$emitter.find('.emitter_chevron_icon').first() || null
		this._$emitter.on('click', () => this.onClick(300))
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Accordion
	 * @return {undefined}
	 */
	onClick(duration = 0) {
		console.log(`${this.constructor.name}.onClick`)
		this._$target.stop().slideToggle(duration)
		if (this._$icon) this._$icon.toggleClass('fa-plus fa-minus')
		if (this._$icon2) this._$icon2.toggleClass('fa-chevron-down fa-chevron-up')
		if (this._$icon3) this._$icon3.toggleClass('rotate-180')
	}
}
