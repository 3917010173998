/**
 * @file CheckAll
 */
'use strict'

/**
 * @classname CheckAll
 * @classdesc 全てのチェックボックスをチェックする
 */
export default class CheckAll {
	/**
	 * @constructor
	 * @param {jQuery} $e - #checkAll
	 */
	constructor($e) {
		console.log('CheckAll.constructor')
		this._$e = $e
		$e.on('click', () => this.onClick())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof CheckAll
	 * @return {undefined}
	 */
	onClick() {
		console.log('CheckAll.onClick')
		const $target = $('.input-delivery-date');
		if (this._$e.prop('checked')) {
			$target.prop('checked', true);
		} else {
			$target.prop('checked', false);
		}
	}
}
