/**
 * @file ファイルアップロード
 */
'use strict'

/**
 * @classname UploadPhoto
 * @classdesc ファイルアップロード
 */
export default class UploadPhoto {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('UploadPhoto.constructor')
		this._$e = $e
		this._$input = this._$e.find('input[type=file]')
		this.attrName = this._$input.attr('data-name')
		this._$input.on('change', (e) => this.onChange(e))

		this._$displayArea = this._$e.find('.display-area')
		this._$previewArea = this._$e.find('.preview-area')
		this._$e.find('.btn-delete-init-photo').on('click', () => this.deleteInitPhoto())
	}

	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onChange(e) {
		console.log('UploadPhoto.onChange', e)
		// onload内で使うのでthisをbindするために変数で保持する
		const $previewArea = this._$previewArea
		const $displayArea = this._$displayArea
		const onDeletePhoto = this.onDeletePhoto
		const input = this._$input

		const files = e.target.files
		const maxMB = window.const.FILE.MAX_MB
		const maxSize = maxMB * 1024 * 1024
		//fileの値は空ではなくなるはず
		if (files.length) {
			//file[0]にアクセスする
			const image_ = files[0]

			//添付されたのが本当に画像かどうか、ファイル名と、ファイルタイプを正規表現で検証する
			if (maxSize < image_.size) {
				alert(`${maxMB}MB以下で添付してください。`)
				return false
			} else {
				// 今の表示を削除
				$previewArea.children().remove()
				//window.FileReaderに対応しているブラウザどうか
				if (window.FileReader) {
					//FileReaderをインスタンス化
					const reader_ = new FileReader()
					//添付ファイルの読み込みが成功したときに実行されるイベント（成功時のみ）
					//一旦飛ばしてreader_ .readAsDataURLが先に動く
					reader_.onload = function () {
						//Data URI Schemeをimgタグのsrcにいれてリアルタイムに添付画像を描画する
						// ここでtailwindClassではなく、styleを使うのは、tailwindClassがコンパイルされていない可能性があるため
						const element = `<div class="image-box img-area">
						<img class="image" src="${reader_.result}" alt="">
						<button class="btn btn-delete-regist-photo" type="button">×</button>
						</div>`
						const inputNameAttr = input.attr('data-name')
						$displayArea.css('display', 'none')
						$previewArea.append(element)
						const clone = input.clone().removeClass().addClass('uploaded').css('display', 'none').attr('name', inputNameAttr)
						$previewArea.find('.img-area').append(clone)
						$previewArea.find('.btn-delete-regist-photo').on('click', () => onDeletePhoto($previewArea, $displayArea, input))
					}
					//Data URI Schemeを取得する
					reader_.readAsDataURL(image_)
				}
				return false
			}
		}
	}

	/**
	 * 画像の削除
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onDeletePhoto($previewArea, $displayArea, input) {
		console.log('UploadPhoto.onDeletePhoto')
		$displayArea.css('display', 'flex')
		$previewArea.children().remove()
		input.val(null)
	}

	/**
	 * サーバー上の画像の削除
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	deleteInitPhoto() {
		console.log('UploadPhoto.deleteInitPhoto')
		
		let name = this.attrName
		const targetSuffix = ']'
		const lastIndex = name.lastIndexOf(targetSuffix)

		// data-nameの最後の文字が']'であれば、その前に'-delete'を入れる
		if (name.indexOf(targetSuffix, name.length - targetSuffix.length) !== -1) {
			name = name.substring(0, lastIndex) + '-delete' + name.substring(lastIndex)
		}else {
			name = name + '-delete'
		}

		const input = `<input type="hidden" name="${name}" value="${this._$displayArea.attr('data-photo-id')}">`
		this._$displayArea.children().remove()
		this._$displayArea.append(input)
	}
}
