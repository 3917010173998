"use strict";

// utility class
import ApplyClass from "../Utils/ApplyClass";

import Form from "../Controller/Form";

// 他案件から持ってきた
import Validate from "../View/Form/Validate";
import Submit from "../View/Form/Submit";
import ZenkakuToHankaku from "../View/Form/ZenkakuToHankaku";
import HasCounter from "../View/Form/HasCounter";
import Select2 from "../View/Form/Select2";
import DatePicker from "../View/Form/DatePicker";
import Editor from "../View/Form/Editor";
import DatepickerClose from "../View/Form/DatepickerClose";
import ImageRotate from "../View/Form/ImageRotate";
import ChangeRotate from "../View/Form/ChangeRotate";
import DeletePhoto from "../View/Form/DeletePhoto";

// 作った：汎用的な機能
import AddTemplate from "../View/Form/AddTemplate";
import UploadPhoto from "../View/Form/UploadPhoto";
import UploadPhotoMultiple from "../View/Form/UploadPhotoMultiple";
import OpenConfirmModal from "../View/Form/OpenConfirmModal";
import TogglePassword from "../View/Form/TogglePassword";
import InputTextareaResize from "../View/Form/InputTextareaResize";
import CheckActivation from "../View/Form/CheckActivation";
import EditorTest from "../View/Form/EditorTest";
import PreviewArticle from "../View/Form/PreviewArticle";
import PreventExit from "../View/Form/PreventExit";
import DeleteItem from "../View/Form/DeleteItem";

// 作った : 汎用的ではない

$(() => {
	const FormController = new Form();
	// validate
	$(".validate").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Validate, $e, [$e, FormController]);
	});
	// 全角→半角変換
	$(".zen2han").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ZenkakuToHankaku, $e, [$e, FormController]);
	});
	// カウンター
	$(".has-counter").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(HasCounter, $e, [$e, FormController]);
	});
	// submit
	$("[type=submit][data-form]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Submit, $e, [$e, FormController]);
	});
	// 削除確認モーダルを開く
	$('.btn-delete-item').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(DeleteItem, $e, [$e, FormController])
	})
	// select2
	$(".select2").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Select2, $e, [$e, FormController]);
	});
	// Air  Datepicker
	$("[data-datepicker=true]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DatePicker, $e, [$e, FormController]);
	});
	//  写真削除
	$(".btn-delete-photo").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeletePhoto, $e, [$e, FormController]);
	});
	// EditorJS
	$("#codex-editor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Editor, $e, [$e, FormController]);
	});
	// プレビュー
	$(".btn-article-preview").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PreviewArticle, $e, [$e, FormController]);
	});
	// datepickerクリア
	$(".btn-datepicker-close").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DatepickerClose, $e, [$e, FormController]);
	});
	// 画像回転
	$("[data-rotate=90]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageRotate, $e, [$e, FormController]);
	});
	$("[data-rotate=270]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageRotate, $e, [$e, FormController]);
	});
	// サムネイル回転
	$("#rotate").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeRotate, $e, [$e, FormController]);
	});
	// パスワードの表示・非表示切り替え
	$(".btn-toggle-password").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TogglePassword, $e, [$e, FormController]);
	});
	// テキストエリアの入力を監視しリサイズする
	$(".input-textarea-resize").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(InputTextareaResize, $e, [$e, FormController]);
	});
	// 対象フォームをコピーして増やす
	$('[data-template-area-type="wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(AddTemplate, $e, [$e, FormController]);
	});
	// チェックボックスでinputエリアを活性化
	$('[data-check-activation-type="wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckActivation, $e, [$e, FormController]);
	});
	// ファイルアップロード
	$(".block-file-upload").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(UploadPhoto, $e, [$e, FormController]);
	});
	// ファイルアップロード(multiple)
	$(".block-file-upload-multiple").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(UploadPhotoMultiple, $e, [$e, FormController]);
	});
	// 確認モーダルを開いた時にモーダル内のformにinput要素を追加する
	$(".btn-open-confirm-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(OpenConfirmModal, $e, [$e, FormController]);
	});
	// EditorJsの確認用
	$(".btn-editor-test").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(EditorTest, $e, [$e, FormController]);
	});
	// 離脱防止アラート
	$(".prevent-exit").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PreventExit, $e, [$e, FormController]);
	});
});
