/**
 * @file スムーススクロール
 *
 */
'use strict'

/**
 * @classname SmoothScroll
 * @classdesc TOPへ戻る
 */
export default class SmoothScroll {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('SmoothScroll.constructor')
		this._$e = $e
		$e.on('click', (e) => {
			this.onClick(e)
		})
	}

	/**
	 * @memberof SmoothScroll
	 */
	onClick(e) {
		console.log('SmoothScroll.onClick')
		e.preventDefault()
		// タブコンポーネントのスムーススクロールは無視する
		if (this._$e.attr('role') === 'tab') return

		const href = this._$e.attr('href')
		const target = $(href === '#' || href === '' ? 'html' : href)
		const position = target.offset().top - 63
		$('html, body').animate({ scrollTop: position }, 500, 'swing')
	}
}
