/**
 * @file テーブルソート
 */
'use strict'

import Common from '../../Utils/Common'

/**
 * @classname TableSort
 * @classdesc テーブルソート
 */
export default class TableSort {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableSort.constructor')
		this._$e = $e
		$e.on('change', () => this.onChange())
	}

	/**
	 * changeのコールバック
	 *
	 * @memberof TableSort
	 * @return {undefined}
	 */
	onChange() {
		console.log('TableSort.onChange')
		const value = this._$e.val()
		const defaultValue = $('.table-sort option').eq(0).val()
		console.log(defaultValue)
		console.log(value)
		if (defaultValue === value) {
			this.tableSort()
		} else {
			this.tableSort(value)
		}
	}

	/**
	 * テーブルコントロール
	 *
	 * @memberof TableSort
	 * @param {string} value - ソート
	 * @return {undefined}
	 */
	tableSort(value = null) {
		console.log('TableSort.tableSort')
		let url = Common.getCurrentURL()
		console.log(url)
		let param = ''
		let params = Common.getParam()
		console.log(params)
		console.log(value)
		if (value) {
			if (Object.keys(params).length > 0) {
				console.log(Object.keys(params).length)
				params['sort'] = value
				let prefix = '?'
				for (const key in params) {
					param += `${prefix}${key}=${params[key]}`
					prefix = '&'
				}
			} else {
				if (value) param = `?sort=${value}`
			}
		} else {
			if (Object.keys(params).length > 0) {
				let prefix = '?'
				for (const key in params) {
					if (key !== 'sort') {
						param += `${prefix}${key}=${params[key]}`
						prefix = '&'
					}
				}
			}
		}
		console.log(`${url}${param}`)
		location.href = `${url}${param}`
	}
}
