/**
 * @file 写真削除
 */
'use strict';

/**
 * @classname DeletePhoto
 * @classdesc 写真削除
 */
export default class DeletePhoto {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('DeletePhoto.constructor');
		this._$e = $e;
		this._controller = controller;
		this._userId = parseInt($('#user_id').val());
		this._photoId = parseInt($e.attr('data-photo-id'));
		$e.on('click', () => this.onClick());
		controller.on('deletePhotoComplete', () => this.onDeletePhotoComplete());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof DeletePhoto
	 * @return {undefined}
	 */
	onClick() {
		console.log('DeletePhoto.onClick');
		this._$e.closest('.form-group').find('.col-md-8').find('input[type=hidden]').val('');
		const $wrap = this._$e.closest('.block-photo-wrap');
		$wrap.find('.image-box').remove();
		$wrap.find('input[type=hidden]').val(this._photoId);
		$('.block-thumb-rotate').hide();
		$('#rotate').val('0');
	};
}
