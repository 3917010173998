/**
 * @file STARS作成
 */
'use strict';

/**
 * @classname ModalClose
 * @classdesc STARS作成
 */
export default class ModalClose {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ModalClose.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * モーダルクローズ
	 *
	 * @memberof ModalClose
	 * @return {undefined}
	 */
	onClick() {
		console.log('ModalClose.onClick');
		const $modal = this._$e.closest('.modal');
		$modal.removeClass('show').hide();
	};
}
