import buttonIcon from './svg/button-icon.svg';
import ajax from '@codexteam/ajax';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, readOnly }) {
    console.log('ui.constructor');
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.readOnly = readOnly;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [ this.CSS.imageContainer ]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: make('div', this.CSS.imagePreloader),
      caption: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly,
      }),
    };

    /**
     * Create base structure
     *  <wrapper>
     *    <image-container>
     *      <image-preloader />
     *    </image-container>
     *    <caption />
     *    <select-file-button />
     *  </wrapper>
     */
    this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
    this.nodes.wrapper.appendChild(this.nodes.caption);
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
    this.rotate = 0;
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      lightbox: 'lightbox',
      disabled: 'disabled',
      caption: 'image-tool__caption',
      imageLeft: 'image-tool--left',
      imageLarge: 'image-tool--large',
    };
  };

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  /**
   * Renders tool UI
   *
   * @param {ImageToolData} toolData - saved tool data
   * @returns {Element}
   */
  render(toolData) {
    console.log(`ui.render`);
    console.log(toolData);
    if (!toolData.file || Object.keys(toolData.file).length === 0) {
      this.toggleStatus(Ui.status.EMPTY);
    } else {
      this.toggleStatus(Ui.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }

  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton() {
    const button = make('div', [ this.CSS.button ]);

    button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  /**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

    this.toggleStatus(Ui.status.UPLOADING);
  }

  /**
   * Hide uploading preloader
   *
   * @returns {void}
   */
  hidePreloader() {
    this.nodes.imagePreloader.style.backgroundImage = '';
    this.toggleStatus(Ui.status.EMPTY);
  }

  /**
   * Shows an image
   *
   * @param {string} url - image source
   * @param {number} img_id - image_id
   * @returns {void}
   */
  fillImage(url, img_id) {
    console.log(`image.fillImage`);
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

    let attributes = {
      src: url,
    };

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load';

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    if (tag === 'VIDEO') {
      /**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
      attributes.autoplay = true;
      attributes.loop = true;
      attributes.muted = true;
      attributes.playsinline = true;

      /**
       * Change event to be listened
       *
       * @type {string}
       */
      eventName = 'loadeddata';
    }

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = make(tag, [this.CSS.imageEl], attributes);
    this.nodes.imageEl.setAttribute('data-img-id', img_id);

    this.nodes.lightbox = make('a', [this.CSS.lightbox], {href: 'javascript:void(0);'});
    this.nodes.lightbox.appendChild(this.nodes.imageEl);

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(Ui.status.FILLED);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = '';
      }
    });

    this.nodes.imageContainer.appendChild(this.nodes.lightbox);
    this.nodes.wrapper.classList.add(this.CSS.imageLeft, this.CSS.imageLarge);

    this.nodes.inputImgId = make('input', 'img_id', {
      type:'hidden',
      name: 'img_id[]',
      value: img_id
    });
    this.nodes.imageContainer.appendChild(this.nodes.inputImgId);

    const timer = setInterval(() => {
      console.log(`fillImage rotate: ${this.rotate}`);
      this.imageWidth = this.nodes.imageEl.clientWidth;
      this.imageHeight = this.nodes.imageEl.clientHeight;
      if(this.imageWidth !== 0) clearInterval(timer);
      if(this.rotate === 90 || this.rotate === 270){
        const scale = this.imageWidth / this.imageHeight;
        const wrapperHeight = Math.ceil(this.imageWidth * scale);
        const margin = (this.imageHeight - wrapperHeight) / -2;
        this.nodes.imageEl.style.transform = `scale(${scale}) rotate(${this.rotate}deg)`;
        this.nodes.imageEl.style.marginTop = `${margin}px`;
        this.nodes.imageEl.style.marginBottom = `${margin}px`;
      }else if(this.rotate === 180){
        this.nodes.imageEl.style.transform = `rotate(${this.rotate}deg)`;
      }
    },100);
  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text) {
    if (this.nodes.caption) {
      this.nodes.caption.innerHTML = text;
    }
  }

  /**
   * Changes UI status
   *
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
  toggleStatus(status) {
    console.log(`ui.toggleStatus: ${status}`);
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
      }
    }
  }

  /**
   * Apply visual representation of activated tune
   *
   * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
   * @param {string} status - true for enable, false for disable
   * @returns {void}
   */
  applyTune(tuneName, status) {
    console.log(`ui.applyTune: ${tuneName}/${status}`);
    if(status && (tuneName !== 'lightbox' || tuneName !== 'rotate')){
      this.nodes.wrapper.classList.add(`${this.CSS.wrapper}--${tuneName}`);
    }
    if (tuneName === 'left' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--center`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--center`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--right`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--right`);
      }
    } else if (tuneName === 'center' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--left`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--left`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--right`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--right`);
      }
    } else if (tuneName === 'right' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--left`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--left`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--center`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--center`);
      }
    } else if (tuneName === 'large' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--middle`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--middle`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--small`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--small`);
      }
      if(this.rotate === 90 || this.rotate === 270){
        this.imageWidth = this.nodes.imageEl.clientWidth;
        this.imageHeight = this.nodes.imageEl.clientHeight;
        console.log(`${this.imageWidth}/${this.imageHeight}`);
        const scale = this.imageWidth / this.imageHeight;
        const wrapperHeight = Math.ceil(this.imageWidth * scale);
        const margin = (this.imageHeight - wrapperHeight) / -2;
        this.nodes.imageEl.style.marginTop = `${margin}px`;
        this.nodes.imageEl.style.marginBottom = `${margin}px`;
      }
    } else if (tuneName === 'middle' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--large`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--large`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--small`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--small`);
      }
      if(this.rotate === 90 || this.rotate === 270){
        this.imageWidth = this.nodes.imageEl.clientWidth;
        this.imageHeight = this.nodes.imageEl.clientHeight;
        const scale = this.imageWidth / this.imageHeight;
        const wrapperHeight = Math.ceil(this.imageWidth * scale);
        const margin = (this.imageHeight - wrapperHeight) / -2;
        this.nodes.imageEl.style.marginTop = `${margin}px`;
        this.nodes.imageEl.style.marginBottom = `${margin}px`;
      }
    } else if (tuneName === 'small' && status) {
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--large`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--large`);
      }
      if(this.nodes.wrapper.classList.contains(`${this.CSS.wrapper}--middle`)) {
        this.nodes.wrapper.classList.remove(`${this.CSS.wrapper}--middle`);
      }
      if(this.rotate === 90 || this.rotate === 270){
        this.imageWidth = this.nodes.imageEl.clientWidth;
        this.imageHeight = this.nodes.imageEl.clientHeight;
        const scale = this.imageWidth / this.imageHeight;
        const wrapperHeight = Math.ceil(this.imageWidth * scale);
        const margin = (this.imageHeight - wrapperHeight) / -2;
        this.nodes.imageEl.style.marginTop = `${margin}px`;
        this.nodes.imageEl.style.marginBottom = `${margin}px`;
      }
    }
    if(tuneName === 'lightbox'){
      console.log(this.nodes.lightbox);
      if(this.nodes.lightbox){
        if(status){
          this.nodes.lightbox.classList.remove(this.CSS.disabled);
        }else{
          this.nodes.lightbox.classList.add(this.CSS.disabled);
        }
      }
    }
    if(tuneName === 'rotate'){
      if(isNaN(this.rotate)) this.rotate = 0;
      console.log(this.rotate);
      if(this.nodes.imageEl){
        if(this.rotate === 0){
          this.nodes.imageEl.style.transform = '';
          this.nodes.lightbox.style.height = '';
          this.nodes.imageContainer.style.height = '';
          this.nodes.imageEl.style.marginTop = '';
          this.nodes.imageEl.style.marginBottom = '';
        }else if(this.rotate === 90){
          this.imageWidth = this.nodes.imageEl.clientWidth;
          this.imageHeight = this.nodes.imageEl.clientHeight;
          console.log(`${this.imageWidth}/${this.imageHeight}`);
          const scale = this.imageWidth / this.imageHeight;
          const wrapperHeight = Math.ceil(this.imageWidth * scale);
          const margin = (this.imageHeight - wrapperHeight) / -2;
          this.nodes.imageEl.style.transform = `scale(${scale}) rotate(90deg)`;
          this.nodes.imageEl.style.marginTop = `${margin}px`;
          this.nodes.imageEl.style.marginBottom = `${margin}px`;
        }else if(this.rotate === 180){
          this.nodes.imageEl.style.transform = `rotate(180deg)`;
          this.nodes.lightbox.style.height = '';
          this.nodes.imageContainer.style.height = '';
          this.nodes.imageEl.style.marginTop = '';
          this.nodes.imageEl.style.marginBottom = '';
        }else if(this.rotate === 270){
          const scale = this.imageWidth / this.imageHeight;
          const wrapperHeight = Math.ceil(this.imageWidth * scale);
          const margin = (this.imageHeight - wrapperHeight) / -2;
          this.nodes.imageEl.style.transform = `scale(${scale}) rotate(270deg)`;
          this.nodes.imageEl.style.marginTop = `${margin}px`;
          this.nodes.imageEl.style.marginBottom = `${margin}px`;
        }
      }
    }
  }
}

/**
 * Helper for making Elements with attributes
 *
 * @param  {string} tagName           - new Element tag name
 * @param  {Array|string} classNames  - list or name of CSS class
 * @param  {object} attributes        - any attributes
 * @returns {Element}
 */
export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
