import { make } from './ui';
import bgIcon from './svg/background.svg';
import borderIcon from './svg/border.svg';
import stretchedIcon from './svg/stretched.svg';

/**
 * Working with Block Tunes
 */
export default class Tunes {
  /**
   * @param {object} tune - image tool Tunes managers
   * @param {object} tune.api - Editor API
   * @param {object} tune.actions - list of user defined tunes
   * @param {Function} tune.onChange - tune toggling callback
   * @param {class} tune.ui - ui.js
   */
  constructor({ api, actions, onChange , ui}) {
    console.log('tunes.constructor');
    this.api = api;
    this.actions = actions;
    this.onChange = onChange;
    this.buttons = [];
    this._width = '';
    this.ui = ui;
  }

  /**
   * Available Image tunes
   *
   * @returns {{name: string, icon: string, title: string}[]}
   */
  static get tunes() {
    return [
      {
        name: 'large',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 40 40" height="20" viewBox="0 0 40 40"><path d="M36.48,31.9,33,36.54a26.47,26.47,0,0,1-8-5.91A30.5,30.5,0,0,1,20,23a23.72,23.72,0,0,1-4.64,7.59,27.15,27.15,0,0,1-8,6.19L3.72,32.18a22.53,22.53,0,0,0,8.58-6.33,20.46,20.46,0,0,0,4.5-9.14H3.86V12.35H17.5V3.78h5.06v8.57H36.2v4.36H23.12a19.11,19.11,0,0,0,3.66,7.74,30.63,30.63,0,0,0,3.8,3.93A22.73,22.73,0,0,0,36.48,31.9Z" /></svg>`,
        title: 'サイズ大（100%）'
      },
      {
        name: 'middle',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 40 40" height="20" viewBox="0 0 40 40"><path d="M5.1,27.29V10H17.19V4.09h5.06V10H34.49V27.29H29.56V25.75H22.25V36.86H17.19V25.75H9.88v1.54Zm4.78-6h7.31V14.36H9.88Zm12.37,0h7.31V14.36H22.25Z" /></svg>`,
        title: 'サイズ中（75%）'
      },
      {
        name: 'small',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 40 40" height="20" viewBox="0 0 40 40"><path d="M7.41,18.86a39,39,0,0,0,1.83-8l5.2,1.55a50.07,50.07,0,0,1-2,8.58,27.19,27.19,0,0,1-5.06,9.28l-4.36-3.8A22,22,0,0,0,7.41,18.86Zm10,11.25v-26h5.07v29c0,2.34-1.18,3.52-3.52,3.52H14.3l-1.69-4.93H16C16.92,31.75,17.39,31.23,17.39,30.11Zm19-3.8L31.88,30a29.08,29.08,0,0,1-4.5-8.16A44.72,44.72,0,0,1,25,12.53l4.92-1.69A44.33,44.33,0,0,0,32.16,19,29,29,0,0,0,36.38,26.31Z" /></svg>`,
        title: 'サイズ小（50%）'
      },
      {
        name: 'left',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 23h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 45h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`,
        title: '左寄せ'
      },
      {
        name: 'center',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 23c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 45c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`,
        title: '中央寄せ'
      },
      {
        name: 'right',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 19h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 41h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg>`,
        title: '右寄せ'
      },
      {
        name: 'withBorder',
        icon: borderIcon,
        title: '枠有り',
      },
      {
        name: 'lightbox',
        icon: '拡大',
        title: '画像クリックで拡大',
      },
      {
        name: 'rotate',
        icon: '回転',
        title: '右に90度回転',
      },
    ];
  }


  /**
   * Styles
   *
   * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
   */
  get CSS() {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'image-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive,
      width: 'ce-settings__width',
      input: 'ce-settings__width__input',
      save: 'ce-settings__width__save',
      reset: 'ce-settings__width__reset',
    };
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   *
   * @param {ImageToolData} toolData - generate Elements of tunes
   * @returns {Element}
   */
  render(toolData) {
    const wrapper = make('div', this.CSS.wrapper);

    this.buttons = [];

    const tunes = Tunes.tunes.concat(this.actions);

    tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title);
      const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
        title,
      });

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action);
      });

      el.dataset.tune = tune.name;
      console.log(toolData[tune.name]);
      el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

      this.buttons.push(el);

      this.api.tooltip.onHover(el, title, {
        placement: 'top',
      });

      wrapper.appendChild(el);
    });

    return wrapper;
  }


  /**
   * Clicks to one of the tunes
   *
   * @param {string} tuneName - clicked tune name
   * @param {Function} customFunction - function to execute on click
   */
  tuneClicked(tuneName, customFunction) {
    console.log('tunes.tuneClicked');
    if (typeof customFunction === 'function') {
      if (!customFunction(tuneName)) {
        return false;
      }
    }

    const button = this.buttons.find(el => el.dataset.tune === tuneName);

    if(tuneName !== 'rotate'){
      button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));
    }else{
      this.ui.rotate = this.ui.rotate + 90;
      if(this.ui.rotate === 360) this.ui.rotate = 0;
      console.log(`rotate: ${this.ui.rotate}`);

      if(this.ui.rotate === 0){
        button.classList.remove(this.CSS.buttonActive);
      }else{
        button.classList.add(this.CSS.buttonActive);
      }
    }

    const left = this.buttons.find(el => el.dataset.tune === 'left');
    const center = this.buttons.find(el => el.dataset.tune === 'center');
    const right = this.buttons.find(el => el.dataset.tune === 'right');
    const large = this.buttons.find(el => el.dataset.tune === 'large');
    const middle = this.buttons.find(el => el.dataset.tune === 'middle');
    const small = this.buttons.find(el => el.dataset.tune === 'small');

    if (tuneName === 'left') {
      center.classList.remove(this.CSS.buttonActive);
      right.classList.remove(this.CSS.buttonActive);
    } else if(tuneName === 'center') {
      left.classList.remove(this.CSS.buttonActive);
      right.classList.remove(this.CSS.buttonActive);
    } else if(tuneName === 'right') {
      left.classList.remove(this.CSS.buttonActive);
      center.classList.remove(this.CSS.buttonActive);
    } else if(tuneName === 'large') {
      middle.classList.remove(this.CSS.buttonActive);
      small.classList.remove(this.CSS.buttonActive);
    } else if(tuneName === 'middle') {
      large.classList.remove(this.CSS.buttonActive);
      small.classList.remove(this.CSS.buttonActive);
    } else if(tuneName === 'small') {
      large.classList.remove(this.CSS.buttonActive);
      middle.classList.remove(this.CSS.buttonActive);
    }

    this.onChange(tuneName);
  }
}
