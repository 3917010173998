/**
 * @file 検索
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname TableSearch
 * @classdesc 検索
 */
export default class TableSearch {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-table-search')
	 */
	constructor($e) {
		console.log('TableSearch.constructor');
		this._$e = $e;
		this._$target = $(`#${$e.attr('data-target')}`)
		$e.on('click', () => this.onClick());
		//this._$target.find('[data-search-item="keyword"]').on(('keypress'), e => this.onKeypress(e));

		if (this._$target && this._$target.find('[data-search-item]').length > 0 ) {
			this._$target.find('[data-search-item]').on(('keypress'), e => this.onKeypress(e));
		}else {
			$('[data-search-item]').on(('keypress'), e => this.onKeypress(e));
		}
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof TableSearch
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableSearch.onClick');
		let url = this._$e.hasClass('btn-user-board-search')
			? '/user/board'
			: Common.getCurrentURL()
		console.log(url);
		let param = this._getSearchQuery();
		console.log(`${url}${param}`);
		location.href = `${url}${param}`;
	};


	/**
	 * keypressのコールバック
	 *
	 * @memberof EnterSubmit
	 * @return {undefined}
	 */
	onKeypress(e) {
		console.log('EnterSubmit.onKeypress');
		const key = e.keyCode || e.charCode || 0;
		if (key === 13) {
			let url = this._$e.hasClass('btn-user-board-search')
				? '/user/board'
				: Common.getCurrentURL()
			console.log(url);
			let param = this._getSearchQuery();
			console.log(`${url}${param}`);
			location.href = `${url}${param}`;
		}
	};


	/**
	 * 検索GETパラメーター取得
	 *
	 * @memberof Table
	 * @return {string} query - 検索GETパラメーター
	 */
	_getSearchQuery() {
		console.log('Table._getSearchQuery');
		let prefix = '?';
		let query = '';
		let radioName;
		let $searchItems
		
		// data-targetが存在する場合
		if (this._$target && this._$target.find('[data-search-item]').length > 0 ) {
			$searchItems = this._$target.find('[data-search-item]')
		}else {
			$searchItems = $('[data-search-item]')
		}

		$searchItems.each((idx, e) => {
			const $e = $(e);
			if ($e.attr('data-search-type') === 'radio') {
				const name = $e.attr('name');
				if (radioName !== name) {
					const checked = $(`input[type=radio][name=${name}]:checked`);
					const value = checked.val();
					if (value !== 'all') {
						query += `${prefix}${$e.attr('data-search-item')}=${value}`;
						prefix = '&';
					}
					radioName = name;
					if (checked.attr('data-search-inside') === 'true' && value !== 'all') {
						this._open = true;
					}
				}
			} else if ($e.attr('data-search-type') === 'checkbox') {
				if ($e.prop('checked')) {
					const value = $e.val();
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
					if ($e.attr('data-search-inside') === 'true') {
						this._open = true;
					}
				}
			} else if ($e.attr('data-search-type') === 'array_base') {
				const name = $e.attr('data-search-item');
				let value = '';
				value = $(`[data-search-item=${name}`).map((idx, e) => {
					const $e = $(e);
					if ($e.prop('checked')) return $e.val();
				}).get().join('|');
				if (value.length > 0) {
					query += `${prefix}${name}=${value}`;
					prefix = '&';
				}
			} else if ($e.attr('data-search-type') === 'array') {

			} else if ($e.attr('data-search-type') === 'multiple') {
				console.log($e.attr('id'));
				const name = $e.attr('data-search-item');
				let value = '';
				value = $e.find('option:selected').map((idx, e) => {
					return $(e).val();
				}).get().join('|');
				if (value.length > 0) {
					query += `${prefix}${name}=${value}`;
					prefix = '&';
				}
			} else {
				if ($e.is(':invalid')) {
					console.log($e.attr('data-search-item') + ': ' + $e.is(':valid'));
					Common.showErrorModal();
					this._error = true;
				}
				const value = encodeURIComponent($e.val());
				console.log(value);
				if (value !== '') {
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
				}
			}
		});
		return query;
	};
}
