/**
 * @file テキストエリアの入力を監視しリサイズする
 */
'use strict'

/**
 * @classname InputTextareaResize
 * @classdesc テキストエリアの入力を監視しリサイズする
 */
export default class InputTextareaResize {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-toggle-password')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._clientHeight = this._$e.height()
		$e.on('input', () => this.onChange())
	}

	/**
	 * onChangeのコールバック
	 *
	 * @memberof InputTextareaResize
	 * @return {undefined}
	 */
	onChange() {
		console.log(`${this.constructor.name}.onChange`)
		// borderの高さ(2px)足してる
		this._$e.css('height', this._clientHeight + 2)
		const scrollHeight = this._$e.get(0).scrollHeight
		// borderの高さ(2px)足してる
		this._$e.css('height', scrollHeight + 2)
	}
}
