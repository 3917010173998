/**
 * @file FormAPIUpload
 */
'use strict'
import Common from '../../../Utils/Common'

/**
 * @classname FormAPIUpload
 * @classdesc
 */
export default class FormAPIUpload {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		this._$e = $e;
		this._controller = controller
	}

	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormAPIUpload
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`)
		// バリデーション前に行う処理を記載（終了）

		const fileInput = $form.find('input[type=file]');
		const $submit = $(`[data-form="#${$form.attr('id')}"]`);

		let cnt = 0
		//一度に保存できる画像の枚数は40枚まで
		if(fileInput.length > 0){
			fileInput.each((idx, e) => {
				const $e = $(e)
				if($e.hasClass('uploaded')) {
					cnt++
				}
			})
		}

		if(cnt > 40){
			Common.showErrorModal('一度に保存できる画像は40枚までです')
			console.log('画像の枚数チェック：' + cnt)
			$submit.removeClass('clicked')
			return;
		}

		if($form.find('[name="mode-preview"]').length){
			// プレビューの時はバリデーションを飛ばす
			this._controller.createHtml(false)
			this._controller.completePostProcessing($form);
			return;
		// 通常の保存処理は、保存ボタンのdata-actionでform actionを再指定する
		}else {
			this._controller.createHtml(true)
			const action = this._$e.attr('data-action');
			$form.attr('action', action);
			this._controller.completePreProcessing($form)
		}
	}

	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormAPIUpload
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log(`${this.constructor.name}.postProcessing`)
		const reader = new FileReader()
		reader.onload = this._controller.apiUpload($form)

		// バリデーション後に行う処理を記載（終了）
		// this._controller.completePostProcessing($form)
	}
}
