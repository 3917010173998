"use strict";

// utility class
import ApplyClass from "../Utils/ApplyClass";
import Common from "../Utils/Common";
import Basic from "../Controller/Basic";

// 他案件から持ってきた
import SmoothScroll from "../View/Basic/SmoothScroll";
import Tab from "../View/Basic/Tab";
import Modal from "../View/Basic/Modal";
import ModalClose from "../View/Basic/ModalClose";
import CheckAll from "../View/Basic/CheckAll";
import BtnDeleteItems from "../View/Basic/BtnDeleteItems";

// 検索系
import TableSort from "../View/Basic/TableSort";
import Pagination from "../View/Basic/Pagination";
import TableSearch from "../View/Basic/TableSearch";
import ClearSearchCondition from "../View/Basic/ClearSearchCondition";

import CompleteModal from "../View/Basic/CompleteModal";
import Accordion from "../View/Basic/Accordion";
import LayeredAccordion from "../View/Basic/LayeredAccordion";
import Lightbox from "../View/Basic/Lightbox";
import ImageNoDownload from "../View/Basic/ImageNoDownload";
import CloseAlert from "../View/Basic/CloseAlert";

// 作った : 汎用的ではない
import PartsCategoryGroupSelect from "../View/Basic/PartsCategoryGroupSelect";
import Matrix from "../View/Basic/Matrix";

$(() => {
	const BasicController = new Basic();
	// デバイス、ブラウザ判定
	Common.addHtmlClass();
	//スムーススクロール
	$('a[href^="#"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SmoothScroll, $e, [$e, BasicController]);
	});
	// モーダル
	$("[data-modal=true]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Modal, $e, [$e, BasicController]);
	});
	// モーダル閉じる
	$(".modal [data-dismiss=modal]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ModalClose, $e, [$e, BasicController]);
	});
	// 全てのチェックボックスをチェックする
	$("#checkAll").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckAll, $e, [$e, BasicController]);
	});
	// 全てのチェックボックスをチェックする
	$(".btn-delete-items").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BtnDeleteItems, $e, [$e, BasicController]);
	});
	// Alert閉じる
	$(".alert [data-dismiss=alert]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CloseAlert, $e, [$e, BasicController]);
	});
	// テーブルソート
	$(".table-sort").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TableSort, $e, [$e, BasicController]);
	});
	// ページネーション
	$(".page-link").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Pagination, $e, [$e, BasicController]);
	});
	// テーブル検索
	$(".btn-table-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TableSearch, $e, [$e, BasicController]);
	});
	// 検索条件クリア
	$(".anchor-clear-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ClearSearchCondition, $e, [$e, BasicController]);
	});
	// Tab
	$(".nav-link").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Tab, $e, [$e, BasicController]);
	});
	// 画面リロードする(閉じる)ボタン
	$(".btn-complete-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CompleteModal, $e, [$e, BasicController]);
	});
	// Accordion
	$('[data-accordion-type="wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Accordion, $e, [$e]);
	});
	// LayeredAccordion
	$('[data-accordion-type="layered-wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(LayeredAccordion, $e, [$e, BasicController]);
	});
	// lightbox
	$("#codex-editor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Lightbox, $e, [$e, BasicController]);
	});
	// 画像ダウンロード禁止
	$(".image").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageNoDownload, $e, [$e, BasicController]);
	});
	// パーツのカテゴリーとグループのセレクトボックスの連動
	$("#parts-create").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PartsCategoryGroupSelect, $e, [$e, BasicController]);
	});
	$("#parts-edit").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PartsCategoryGroupSelect, $e, [$e, BasicController]);
	});
	// マトリクス設定
	$(".matrix").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Matrix, $e, [$e, BasicController]);
	});
});
